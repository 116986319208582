import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useErrorBoundary } from "react-error-boundary";
import { useUser } from '../contexts/UserContext';
import { URL } from '../Helpers';
import LoadingSpinner from './shared/LoadingSpinner';
import './style.scss';
import { Grid, Typography, Button, SvgIcon, Box } from '@mui/material';
import { ReactComponent as SessionBooked } from '../data/session_booked.svg';

const SuccessPage = () => {
  const [sessionDetails, setSessionDetails] = useState(null);


  const { therapist_id, timeslot_id, session_id, share_consent } = useParams();
  const { showBoundary } = useErrorBoundary();
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    //remove session storages of any checkout info progress
    sessionStorage.removeItem('checkoutInProgress')
    sessionStorage.removeItem('timeslotId')
    sessionStorage.removeItem('therapistId')
    // Function to fetch session details from the server
    const bookAppointment = async (therapist_id, timeslot_id, session_id) => {
      // Call backend to book appointment and fetch checkout session details
      try {
        const response = await fetch(`${URL}/book_appointment/${session_id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
          },
          body: JSON.stringify({ therapistId: therapist_id, timeslotId: timeslot_id, dataShareConsent: share_consent })
        });

        if (response.ok) {
          const responseData = await response.json();
          const { checkoutSession, calendarEvent, appointment_Id } = responseData;
          console.log('details', checkoutSession, calendarEvent, appointment_Id)

          // Now you have checkoutSession details and calendarEvent as a string
          console.log('session details', checkoutSession)
          setSessionDetails(checkoutSession);
          // ...
          // Download the calendar event
          const blob = new Blob([calendarEvent], { type: "text/calendar;charset=utf-8" });
          setSessionDetails(responseData.checkoutSession);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'appointment.ics';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

        } else {
          const errorMessage = await response.text();
          showBoundary(new Error(`Could not book appointment: ${errorMessage}`));
        }
      } catch (error) {
        showBoundary(new Error('Error fetching session details: ' + error));
      }
    };

    if (user && session_id && therapist_id && timeslot_id) {
      bookAppointment(therapist_id, timeslot_id, session_id);
    } else if (user && !session_id) {
      showBoundary(new Error("no session ID provided in the URL"));
    }
  }, [user, therapist_id, timeslot_id, session_id, showBoundary, share_consent]);

  const handleNavigate = () => {
    navigate('/book-therapist')
  };

  // useEffect(() => {
  //   if (sessionDetails) {
  //     setTimeout(() => {
  //       navigate('/book-therapist')
  //     }, 30000);
  //   }
  // }, [sessionDetails, navigate]);


  if (!sessionDetails) {
    return (
    <div>
      <LoadingSpinner className="bigger_height" />
    </div>   

    )
  }

  return (
    <div className="prevent_overflow">
      <Grid container justifyContent={'center'} my={18}>
        <Grid bgcolor={"white"} padding={"24px"} gap={"24px"} boxShadow={"0px 5px 10px rgba(74, 89, 129, 0.16)"} borderRadius={"10px"}  xs={11} sm={10} md={10} lg={6} xl={5} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} flex={1}>
          <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"100%", padding:"25px 31px 35px 25px"}} bgcolor={"#d8e0ec"}>
            <SvgIcon component={SessionBooked} style={{ width: '31px', height: '35px', overflow: 'unset' }}/>
          </Box>
          <Typography variant='h32Bold' color={"#4C6EA2"} >Session Booked</Typography>
          <Typography variant='sb20Bold' className="text-center">
            Congrats! Your session with Dimitra has been booked.
            A calendar event file will be downloaded shortly. Check your email for the details.
          </Typography>
          <Button variant='primary' onClick={handleNavigate}>
            <Typography variant='sb16Bolder'>Go to My Appointments</Typography>  
          </Button>
          {/* <h5 className="text-center">
            You will be redirected to the therapy booking page shortly.
          </h5> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default SuccessPage;
