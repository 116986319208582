import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useLocation, useParams } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Typography, Box, Button, Grid, SvgIcon, Card, CardMedia, CardContent, Link, InputLabel, MenuItem, FormControl, Select, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { URL } from "../Helpers";
import { useUser } from '../contexts/UserContext';
import CancelAppointmentModal from '../components/CancelAppointment';
import { ReactComponent as TherapistsIcon } from '../data/psychology.svg';
import { ReactComponent as AppointmentsIcon } from '../data/appointments.svg';
import { useErrorBoundary } from 'react-error-boundary';
import LoadingSpinner from './shared/LoadingSpinner';
import "./BookTherapy.scss";
import './style.scss';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { useStripe } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Checkbox from '@mui/material/Checkbox';
import MyAvatar from "../data/appointments_avatar.png"
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import BookNowButton from './BookNowButton';


const stripePromise = loadStripe(process.env.REACT_APP_stripeKey);

const BookTherapy = () => {
  const [therapists, setTherapists] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [loading, setLoading] = useState(null)
  const [view, setView] = useState("therapists");
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [filter, setFilter] = useState('booked');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 500px)' });
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([])  // available time slots from chosen therapist
  const [availableTimeSlots, setAvailableTimeSlots] = useState([])  // all available time slots
  const { user } = useUser();
  const [therapistId, setTherapistId] = useState(null);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [showDateTime, setShowDateTime] = useState(false);
  const [showDataShareModal, setShowDataShareModal] = useState(false);
  const [consentGiven, setConsentGiven] = useState(true);
  const { id } = useParams();
  const stripe = useStripe();
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [showToast, setShowToast] = useState(false);
  const [timeSlotId, setTimeSlotId] = useState('');
  const [displayPicker, setDisplayPicker] = useState(false)
  const [displayTimePicker, setDisplayTimePicker] = useState(false)
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const isFutureDate = (time) => {
    const slotDate = new Date(time);
    const currentDate = new Date();
    return slotDate > currentDate;
  };
  
  const handleBookNow = async (therapist) => {
    if (!user) {
      navigate('/auth', { state: { from: location }, replace: true });
      return;
    }
    if (!user.uid) {
      setAlertMessage('User ID is missing. Please log in again.');
      setAlertSeverity('error');
      setOpen(true);
      setTimeout(() => setOpen(false), 7000);
      return;
    }
  
    const requestsKey = `therapistRequests_${user.uid}`;
    const requests = JSON.parse(localStorage.getItem(requestsKey) || '[]');
    const currentTime = new Date().getTime();
  
    // Remove requests older than 48 hours
    const updatedRequests = requests.filter(req => currentTime - req.time < 48 * 60 * 60 * 1000);
  
    if (updatedRequests.length >= 2) {
      const oldestRequestTime = Math.min(...updatedRequests.map(req => req.time));
      const timeLeft = 48 * 60 * 60 * 1000 - (currentTime - oldestRequestTime);
      const hoursLeft = Math.ceil(timeLeft / (60 * 60 * 1000));
      setAlertMessage(`You can request appointments with a maximum of 2 therapists every 48 hours. Please try again in ${hoursLeft} hours.`);
      setAlertSeverity('warning');
      setOpen(true);
      setTimeout(() => setOpen(false), 7000);
      return;
    }
  
    if (updatedRequests.some(req => req.therapistId === therapist.id)) {
      const requestTime = updatedRequests.find(req => req.therapistId === therapist.id).time;
      const timeLeft = 48 * 60 * 60 * 1000 - (currentTime - requestTime);
      const hoursLeft = Math.ceil(timeLeft / (60 * 60 * 1000));
      setAlertMessage(`You've already requested an appointment with this therapist. You can request again in ${hoursLeft} hours.`);
      setAlertSeverity('warning');
      setOpen(true);
      setTimeout(() => setOpen(false), 7000);
      return;
    }
  
    if (selectedDate) {
      setSelectedDate(null);
    }
    setDisplayTimePicker(false);
    const times = availableTimeSlots.filter(timeslot => timeslot.therapist_id === therapist.id);
    const futureTimes = times.filter(timeslot => isFutureDate(timeslot.time));
  
    setAvailableTimes(times);
    setSelectedTherapist(therapist.id);
  
    if (futureTimes.length === 0) {
      try {
        const response = await axios.post(`${URL}/request-appointment`, {
          therapist_email: therapist.email,
          user_name: user.displayName
        });
  
        if (response.status === 200) {
          setAlertMessage('Notification sent successfully.');
          setAlertSeverity('success');
  
          // Update requests
          updatedRequests.push({ therapistId: therapist.id, time: currentTime });
          localStorage.setItem(requestsKey, JSON.stringify(updatedRequests));
        } else {
          setAlertMessage('Failed to send notification.');
          setAlertSeverity('error');
        }
      } catch (error) {
        setAlertMessage('Error sending notification.');
        setAlertSeverity('error');
      } finally {
        setOpen(true);
        setTimeout(() => setOpen(false), 7000);
      }
    } else {
      setTimeout(() => {
        setDisplayTimePicker(true);
        setShowDateTime(false);
        setDisplayPicker(true);
      }, 0);
    }
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleBooking = async (therapist) => {
    try {
      // const data = await fetchTherapist(therapist.id);
      setTherapistId(therapist.id)
      if (availableTimes) {
        // console.log(availableTimes)
        const formattedSlots = availableTimes.map(slot => ({
          ...slot,
          timestamp: formatTimeslot(new Date(slot.time))
        }));

        const combinedDateTime = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          selectedTime.hours(),
          selectedTime.minutes(),
          0,
        );
        const utcDateTime = new Date(Date.UTC(
          combinedDateTime.getFullYear(),
          combinedDateTime.getMonth(),
          combinedDateTime.getDate(),
          combinedDateTime.getHours(),
          combinedDateTime.getMinutes()
        ));

        const combinedISO = utcDateTime.toISOString().slice(0, -8);

        const matchingSlot = formattedSlots.find(slot => {
          const slotISO = new Date(slot.time).toISOString().slice(0, -8); // Truncate seconds  
          return slotISO === combinedISO;
        });
        // console.log(matchingSlot)

        if (matchingSlot) {
          setShowDateTime(true)
          setTimeSlotId(matchingSlot.id);
        } else {
          console.log("No matching slot found.");
          setShowDateTime(false)
          setToastMessage("No matching slot found.");
          setToastType("error");
          setShowToast(true);
        }
      }
    } catch (error) {
      console.error("Error fetching time slots:", error);
    }
  }

  const fetchTherapist = async (therapistId) => {
    try {
      const response = await fetch(`${URL}/therapists_timeslots/${therapistId}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setAvailableTimeSlots(data.availableSlots);
      return data;
    } catch (error) {
      throw error;
    }
  };

  const fetchAllTherapists = async () => {
    try {
      const response = await fetch(`${URL}/all_therapists_timeslots`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
          // 'Authorization': `Bearer ${user.accessToken}`
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  };

  const formatTimeslot = (dateObj) => {
    // Format the date
    const dateFormatter = new Intl.DateTimeFormat("en-UK", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const formattedDate = dateFormatter.format(dateObj);

    // Format the time
    const timeFormatter = new Intl.DateTimeFormat("en-UK", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZoneName: "short",
      timezone: "CET"
    });
    const formattedTime = timeFormatter.format(dateObj);

    return {
      date: formattedDate,
      time: formattedTime,
    };
  }

  const toggleConsent = () => {
    setConsentGiven(!consentGiven);
  };

  const onPay = () => {
    handlePay(timeSlotId, therapistId, consentGiven);
    setShowDataShareModal(false);
  };

  const handlePay = async (timeSlotId, therapistID, dataShareFlag) => {
    // Get the user's location
    try {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };

        setLoading(true)
        //save timeslot and therapistID in session storage in case of errors to revert booking
        sessionStorage.setItem('timeslotId', timeSlotId)
        sessionStorage.setItem('therapistId', therapistID)
        sessionStorage.setItem('checkoutInProgress', true)
        // Include the location in the POST request
        const response = await fetch(`${URL}/create-checkout-session`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
          },
          body: JSON.stringify({
            therapistId: therapistID,
            timeslotId: timeSlotId,
            location: location,
            dataShareConsent: dataShareFlag
          })
        });

        const { sessionId, sessionURL, error } = await response.json();
        if (!sessionURL) {
          setLoading(false)
          console.error(`Failed to create checkout session: ${error}`);
          //remove session storages
          sessionStorage.removeItem('timeslotId')
          sessionStorage.removeItem('therapistId')
          return;
        }


        //ELIF comment
        // console.log(sessionURL)
        // window.location.href = sessionURL
        const stripeError = await stripe.redirectToCheckout({ sessionId: sessionId });
        if (stripeError) {
          console.warn('Error:', stripeError);
        }
        //ELIF end comment
        //ELIF uncomment
        // navigate(`/book-success/${therapistID}/${timeslot.id}/ELIF`)
        //ELIF end uncomment
      }, (error) => {
        setLoading(false)
        console.error(`Geolocation error: ${error}`);
        setToastMessage("Please Allow Location permission to continue");
        setToastType('error');
        setShowToast(true);
      });
    } catch (error) {
      setLoading(false)
      console.error(`Error redirecting: ${error}`);
      setToastMessage("Error redirecting. Please try again later");
      setToastType('error');
      setShowToast(true);
    }
  };

  const handleDateChange = (date) => {
    setDisplayTimePicker(true)
    setSelectedDate(date);
    setSelectedTime(null);
  };

  const handleTimeChange = (value) => {
    setSelectedTime(value);
  };

  const filteredAvailableDates = useMemo(() => availableTimes?.map(timeslot => moment.utc(timeslot.time, 'ddd, DD MMM YYYY HH:mm:ss [GMT]')), [availableTimes])

  const isDateDisabled = (date) => {
    const dateStr = moment.utc(date).startOf('day');
    const nextDay = dateStr.clone().add(1, 'day'); // Get the next day
    return filteredAvailableDates?.some(availableDate => availableDate.isSame(nextDay, 'day'));
  };

  const disabledHours = () => {
    if (!selectedDate) return [];

    const selectedDateStr = moment.utc(selectedDate).startOf('day');
    const nextDay = selectedDateStr.clone().add(1, 'day');

    const availableHours = availableTimes
      .filter(slot => moment.utc(slot.time).isSame(nextDay, 'day'))
      .map(slot => moment.utc(slot.time).hour());

    const allHours = Array.from({ length: 24 }, (_, i) => i);

    const disabledHours = allHours.filter(hour => !availableHours.includes(hour));

    return disabledHours;
  };


  const disabledMinutes = (selectedHour) => {
    if (!selectedDate) return [];

    const selectedDateStr = moment.utc(selectedDate).startOf('day').hour(selectedHour);
    const nextDay = moment.utc(selectedDate).startOf('day').add(1, 'day').hour(selectedHour);

    const availableMinutes = availableTimes
      .filter(slot => moment.utc(slot.time).isSame(nextDay, 'hour'))
      .map(slot => moment.utc(slot.time).minute());

    const allMinutes = Array.from({ length: 60 }, (_, i) => i);

    const disabledMinutes = allMinutes.filter(minute => !availableMinutes.includes(minute));

    return disabledMinutes;
  };

  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  const handleViewProfile = (therapist) => {
    navigate(`/view-profile/${therapist.id}`)
  }

  const handleCancelClick = (appointment) => {
    setSelectedAppointment(appointment);
    setShowCancelModal(true);
  };

  const handleCancel = async (appointmentId, cancellationReason) => {
    try {
      setLoading(true)
      const idToken = await user.getIdToken();
      const reactDate = Date.now()

      const response = await fetch(`${URL}/cancel_appointment`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          appointmentId,
          cancellationReason,
          'time_now': reactDate
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      // console.log("Cancellation result:", result);
      handleCloseCancelModal()
      setAppointments([])
      await fetchAppointments()
      setLoading(false)

    } catch (error) {
      console.error("Error cancelling appointment:", error);
      showBoundary(new Error("Error cancelling appointment: " + error))
    }
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
    setSelectedAppointment(null);
  };

  const fetchAppointments = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${URL}/get_user_appointments`, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
        },
      });

      const userAppointments = response.data;
      if (userAppointments.length === 0) {
        console.log("No appointments");
      } else {
        setAppointments(userAppointments);
        filterAppointments(userAppointments, filter);
      }
    } catch (error) {
      console.error('Error fetching appointments:', error);
    } finally {
      setLoading(false)
    }
  };
  const filterAppointments = (appointments, filter) => {

    const now = new Date();
    const filtered = appointments.filter(appointment => {
      const appointmentDate = new Date(`${appointment.date}T${appointment.time}`);
      const oneHourAfterAppointment = new Date(appointmentDate.getTime() + (60 * 60 * 1000));
      if (filter === 'booked') {
        return appointment.status === 'booked' && now < oneHourAfterAppointment;
      } else if (filter === 'passed') {
        return appointment.status === 'booked' && now >= oneHourAfterAppointment;
      } else if (filter === 'cancelled') {
        return appointment.status === 'cancelled';
      }
      return true;
    });
    setFilteredAppointments(filtered);
  };

  useEffect(() => {
    setLoading(true);
    setAppointments([]);

    const fetchTherapists = async () => {
      try {
        const data = await fetchAllTherapists();

        const futureAvailableSlots = data.availableSlots.filter(timeslot => {
          const isFuture = isFutureDate(timeslot.time);
          const isNotBooked = !timeslot.booked;
          return isFuture && isNotBooked;
        });
  
        const filteredTherapists = data.therapistsInfo.map(therapist => {
          const therapistTimes = futureAvailableSlots.filter(timeslot => timeslot.therapist_id === therapist.id);
          return {
            ...therapist,
            availableSlots: therapistTimes
          };
        });
   
        setTherapists(filteredTherapists);
        setAvailableTimeSlots(futureAvailableSlots);
      } catch (error) {
        console.error("Error fetching therapists:", error);
      } finally {
        setLoading(false);
      }
    };
    
    const unbookTimeslot = async (therapistID, timeSlotId) => {
      try {
        setTimeSlotId(null)
        setTherapistId(null)

        const response = await fetch(`${URL}/unbook_timeslot`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
          },
          body: JSON.stringify({
            therapistId: therapistID,
            timeslotId: timeSlotId,
          })
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching therapist information and time slots:", error);
      }
    };

    const checkoutInProgress = sessionStorage?.getItem('checkoutInProgress');

    if (checkoutInProgress === 'true') {
      sessionStorage.removeItem('checkoutInProgress');
      const savedTimeslotID = sessionStorage.getItem('timeslotId');
      const savedTherapistID = sessionStorage.getItem('therapistId');

      if (!savedTherapistID || !savedTimeslotID) {
        console.error("Error while unbooking timeslot, timeslotId or therapistId not found");
      } else {
        // console.log('Unbooking timeslots', savedTherapistID, savedTimeslotID);
        unbookTimeslot(savedTherapistID, savedTimeslotID).then(fetchTherapists)
      }

      // Remove session storages
      sessionStorage.removeItem('timeslotId');
      sessionStorage.removeItem('therapistId');

    }

    if (view === "therapists") {
      fetchTherapists();
    } else if (user && view === "appointments") {
      fetchAppointments();
    } else {
      navigate('/auth', { state: { from: location }, replace: true });
    }

  }, [user, view, filter]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleFilterChange = (selectedFilter) => {
    console.log('Selected filter:', selectedFilter); // Log the selected filter
    setFilter(selectedFilter);
    toggleDropdown();
  };
  
  useEffect(() => {
    console.log('Filtered appointments:', filteredAppointments);
    console.log('Current filter:', filter);
  }, [filteredAppointments, filter]);
  
  
  const openModal = (appointment) => {
    setSelectedAppointment(appointment);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedAppointment(null);
    setModalIsOpen(false);
  };

  const appointmentsByTherapist = {};
  filteredAppointments.forEach(appointment => {
    if (!appointmentsByTherapist[appointment.therapist_email]) {
      appointmentsByTherapist[appointment.therapist_email] = [];
    }
    appointmentsByTherapist[appointment.therapist_email].push(appointment);
  });
  
  const getTherapistByEmail = email => therapists.find(therapist => therapist.email === email);

  return (
    <div className="prevent_overflow">
      <Grid container justifyContent={'center'} maxWidth={"90%"} alignSelf={'center'} flex={1}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} height={"fit-content"}>
          {view === "therapists" ? (
            <>
              <Button sx={{ color: "secondary.main" }}>
                <Box
                  sx={{
                    bgcolor: "primary.darkerBlue",
                    width: "42px",
                    height: "42px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SvgIcon component={TherapistsIcon} width="20px" height="20px" />
                </Box>
                <Typography variant={isSmallScreen ? 'sb16Bolder' : 'sb24Bolder'} sx={{ color: "primary.darkerBlue" }}>Therapists</Typography>
              </Button>
              <div className="vertical-line" style={{ background: "#E3DBD5", height: "2rem" }} />
              <Button onClick={() => setView("appointments")}>
                <SvgIcon component={AppointmentsIcon} width="32px" height="32px" />
                <Typography variant={isSmallScreen ? 'sb16Bold' : 'sb24Bold'} sx={{ color: "primary.darkerBlue" }}>My Appointments</Typography>
              </Button>
            </>
          ) : (
            <>
              <Button sx={{ color: "primary.darkerBlue" }} onClick={() => setView("therapists")} >
                <SvgIcon component={TherapistsIcon} inheritViewBox />
                <Typography variant={isSmallScreen ? 'sb16Bold' : 'sb24Bold'}>Therapists</Typography>
              </Button>
              <div className="vertical-line" style={{ background: "#E3DBD5", height: "2rem" }} />
              <Button sx={{ color: "secondary.main" }} onClick={() => setView("appointments")}>
                <Box
                  sx={{
                    bgcolor: "primary.darkerBlue",
                    width: "42px",
                    height: "42px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SvgIcon component={AppointmentsIcon} width="20px" height="20px" />
                </Box>
                <Typography variant={isSmallScreen ? 'sb16Bolder' : 'sb24Bolder'} sx={{ color: "primary.darkerBlue" }}>My Appointments</Typography>
              </Button>
            </>
          )}
        </Box>
        {view === "therapists" && (
          <Grid container justifyContent={'center'} spacing={0} sx={{ my: 5, display: "flex" }}>
            {loading ? (
              <LoadingSpinner className="bigger_height" />
            ) : (
              <>
                {/* for alerts on errors or success */}
                <Snackbar
                  open={showToast}
                  autoHideDuration={2000}

                  onClose={() => setShowToast(false)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  style={{ marginBottom: '60px' }}
                >
                  <Alert severity={toastType === "error" ? "error" : "success"}>
                    {toastMessage}
                  </Alert>
                </Snackbar>
                {therapists.length === 0 && (
                  <Grid textAlign={'center'}>
                    <Typography color={"#4C6EA2"} variant='sb24Bold'>Currently there are no therapists with available appointment times.</Typography>
                  </Grid>
                )}
                {therapists.map((therapist) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={therapist.id} sx={{ display: "flex", justifyContent: "center", mb: 4, height: "fit-content" }}>
                    <Card className="therapist-card">
                      <CardMedia component="img" image={therapist.img_url} alt={therapist.name} className="therapist-card-img" />
                      <CardContent>
                        <Typography variant="sb20Bold" component="h2" color="otherColors.mainBodyDark">
                          {therapist.name}
                        </Typography>
                        <Typography variant="sb16SemiBold" component="h3" color="primary.darkerBlue">
                          {therapist.therapist_title}
                        </Typography>
                        <hr style={{ border: "1px solid #E5E5E5" }} />
                        <Typography variant="sb16SemiBold" component="h3" color="otherColors.mainBodyDark" sx={{ mb: 4 }}>
                          {therapist.bio}
                        </Typography>
                        <Grid style={{ display: 'flex', justifyContent: 'space-between' }} gap={2} spacing={2}>
                        <Tooltip
                            title={
                              availableTimeSlots.filter(timeslot => isFutureDate(timeslot.time))
                                .some(timeslot => timeslot.therapist_id === therapist.id)
                                ? "Click to book an appointment."
                                : "No available time slots. Request an appointment instead."
                            }
                            placement="top"
                          >
                                <BookNowButton 
                                  therapist={therapist} 
                                  handleBookNow={handleBookNow} 
                                  user={user}
                                  availableTimeSlots={availableTimeSlots}
                                  isFutureDate={isFutureDate}
                                  showDateTime={showDateTime}
                                  selectedTherapist={selectedTherapist}
                                />                        </Tooltip>
                          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                              <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                                {alertMessage}
                              </Alert>
                            </Snackbar>     
                          <Button variant="secondary" onClick={() => handleViewProfile(therapist)}>
                            {/* <SvgIcon component={ProfileIcon} width="10px" height="10px" /> */}
                            <Typography variant="sb16Bolder">View Profile</Typography>
                          </Button>
                        </Grid>
                        <Grid display={'flex'} flexDirection={'column'} gap={"2px"}>
                          {selectedTherapist === therapist.id && displayPicker && !selectedDate && (
                            <DatePicker
                              open={selectedTherapist === therapist.id}
                              selected={selectedDate}
                              onChange={handleDateChange}
                              minDate={new Date()}
                              dateFormat="yyyy-MM-dd"
                              filterDate={isDateDisabled}
                              hideDisabledOptions={true}
                              allowEmpty={false}
                              placeholderText={'Select Date'}
                              onClickOutside={() => {
                                if (displayPicker) {
                                  setDisplayPicker(false);
                                }
                              }}
                            />
                          )}
                          {selectedDate && selectedTherapist === therapist.id && displayTimePicker && !showDateTime && (
                            <Grid style={{ position: 'absolute' }}>
                              {selectedTime && (
                                <Button
                                  sx={{ width: "fit-content", '&:hover': { border: "1px solid white !important" } }}
                                  variant="secondary"
                                  onClick={() => {
                                    handleBooking(therapist);
                                  }}
                                  style={{ position: "absolute", zIndex: 9999, top: "212px", left: "155px" }}
                                >
                                  <Typography variant="sb16Bolder">Book</Typography>
                                </Button>
                              )}
                              <button
                                onClick={() => {
                                  setDisplayTimePicker(false);
                                }}
                                style={{ position: "absolute", zIndex: 9999, top: "10px", left: "222px", border: "none", backgroundColor: "transparent" }}
                              >
                                <Typography variant="sb16Bolder" color={"white"}>X</Typography>
                              </button>
                              <TimePicker
                                open={displayTimePicker}
                                showSecond={false}
                                value={selectedTime}
                                onChange={handleTimeChange}
                                format="HH:mm"
                                hideDisabledOptions={true}
                                allowEmpty={false}
                                placeholder="Select Time"
                                disabledHours={disabledHours}
                                disabledMinutes={disabledMinutes}

                                style={{ position: 'absolute', zIndex: 1, marginTop: "12px" }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
                {showDateTime && (
                  <Grid xs={12} xl={7} lg={7} md={9} my={4} display={'flex'} flexDirection={'column'} alignSelf={'center'} height={"fit-content"} textAlign={'center'} alignItems={'center'} justifyContent={'center'}>
                    <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} bgcolor={'white'} borderRadius={"10px"} border={"1px solid #E3DBD5"} boxShadow={"0px 5px 10px #E9E2DD"} width={"fit-content"} height={"fit-content"} textAlign={'center'} padding={"24px"} gap={"10px"}>
                      <Grid bgcolor={"#0dff0012"} padding={"5px"} justifyContent={'center'} display={'flex'} alignItems={'center'} gap={"10px"}>
                        <Typography variant='sb20SemiBold'>Selecting date and time:</Typography>
                        <Typography variant='sb20SemiBold' color={"#62C294"}>{selectedDate && selectedDate.toDateString()}</Typography>
                        <Typography variant='sb20SemiBold' color={"#62C294"}>-</Typography>
                        <Typography variant='sb20SemiBold' color={"#62C294"}>{selectedTime.format('HH:mm')}</Typography>
                      </Grid>
                      <Modal style={{ borderRadius: "10px", border: "1px solid #E3DBD5" }} show={showDataShareModal} onHide={() => setShowDataShareModal(false)} centered>
                        <Grid>
                          <Grid bgcolor={"#5C83BF"} padding={"16px"}>
                            <Typography color={"white"} variant='sb24Bold'>Share Insights?</Typography>
                          </Grid>
                          <Grid padding={"24px"} textAlign={'center'}>
                            <Typography variant='sb16Bold'>Allow your therapist to access your sessions' insights, enhancing therapy quality. Your confidentiality is always protected.</Typography>
                            <Grid my={2} justifyContent={'center'} alignItems={'center'} gap={"2px"} display={'flex'}>
                              <Checkbox id="shareData" checked={consentGiven} onChange={toggleConsent} />
                              <Typography variant='sb16Bold'>I allow my therapist to see my session insights.</Typography>
                            </Grid>
                            <Grid sx={{ display: "flex", justifyContent: "center" }}>
                              <Button sx={{ display: "flex", alignItem: "center" }} variant="primary" onClick={onPay}>
                                {/* <SvgIcon component={ArrowForward}/> */}
                                <Typography variant="sb16Bolder">Continue</Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Modal>
                      <Typography my={1} variant='sb24SemiBold'>Do you want to proceed with checkout?</Typography>
                      <Button variant="primary" onClick={() => setShowDataShareModal(true)}>
                        <Typography variant="sb16Bolder">Pay Now</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
        {view === "appointments" && (
          <Grid container flexDirection={'column'} alignItems={'center'} my={5} gap={"40px"}>
            {loading ? (
              <LoadingSpinner className="bigger_height" />
            ) : (
              <>
                <Grid container gap={10} justifyContent='center' alignItems='center'>
                  <Grid position={'relative'}>
                    <Box position={'absolute'} left={"-23px"} zIndex={"2"} width={"180px"} height={"180px"} borderRadius={"100%"} bgcolor={"rgba(92, 131, 191, 0.1)"}></Box>
                    <img src={MyAvatar} alt="Avatar" style={{ position: "relative", top: "30px", left: "0px", zIndex: "3" }} />
                  </Grid>
                  <Grid width={"250px"}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Filter</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter}
                        label="Filter"
                        onChange={(event) => handleFilterChange(event.target.value)}
                      >
                        <MenuItem fontSize={"16px"} fontWeight={"600"} value="booked" sx={{ '&:hover': { backgroundColor: '#00ff3a33' }, '&.Mui-selected': { backgroundColor: '#00ff3a33' } }} selected={filter === 'booked'}>Confirmed</MenuItem>
                        <MenuItem fontSize={"16px"} fontWeight={"600"} value="passed" sx={{ '&:hover': { backgroundColor: '#6c757d1c' }, '&.Mui-selected': { backgroundColor: '#6c757d1c' } }} selected={filter === 'passed'}>Passed</MenuItem>
                        <MenuItem fontSize={"16px"} fontWeight={"600"} value="cancelled" sx={{ '&:hover': { backgroundColor: '#ff001826' }, '&.Mui-selected': { backgroundColor: '#ff001826' } }} selected={filter === 'cancelled'}>Cancelled</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {filteredAppointments.length === 0 ? (
                  <Grid textAlign={'center'} my={5} height={"15rem"} justifyContent={'center'} display={'flex'}>
                    {filter === 'booked' && <h2>Currently you don't have any confirmed appointments.</h2>}
                    {filter === 'passed' && <h2>Currently you don't have any passed appointments.</h2>}
                    {filter === 'cancelled' && <h2>Currently you don't have any cancelled appointments.</h2>}
                  </Grid>
                ) : (
                  <Grid container width={{ xl: '80%' }} justifyContent={'center'} gap={6} mt={7}>
                    {Object.entries(appointmentsByTherapist).map(([therapistEmail, therapistAppointments]) => {
                      const therapist = getTherapistByEmail(therapistEmail);
                      if (!therapist) return null; // Handle cases where therapist data is not found
                      return therapistAppointments.map(appointment => {
                        const appointmentDate = new Date(appointment.time);
                        const now = new Date();
                        const oneHourAfterAppointment = new Date(appointmentDate.getTime() + 60 * 60 * 1000);
                        const isPastAppointment = now >= oneHourAfterAppointment;
                        return (
                          <Grid display={'flex'} justifyContent={'center'}
                            xl={5}
                            lg={5}
                            md={12}
                            sm={12}
                            xs={12}
                            key={appointment.id}
                          >
                            <Card className="appointment-card">
                              <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                {appointment.status === 'cancelled' && (
                                  <Grid mb={2}>
                                    <Grid width={"fit-content"}>
                                      <Typography variant='sb20Medium'>*Cancelled by {appointment.cancellation_issuer}</Typography>
                                    </Grid>
                                  </Grid>
                                )}
                                <Grid flexDirection={{ xs: 'column', sm: 'row' }} mb={2} padding={"0px 24px 0px 24px"} display={'flex'} gap={{ xs: 2, sm: 3 }} alignItems={'center'}>
                                  <Grid>
                                    {/* <Box width={"100px"} height={"100px"} borderRadius={"100%"} border={"2px solid #5C83BF"} bgcolor={"#d8e0ec"}></Box> */}
                                    <img src={therapist.img_url} width={"100px"} height={"auto"} style={{ borderRadius: "100%", border: "2px solid #5C83BF" }} />
                                  </Grid>
                                  <Grid width={{ xs: "100%", sm: "1px" }} height={{ xs: "1px", sm: "4rem" }} className="vertical-line" style={{ background: "#E3DBD5" }} />
                                  <Grid>
                                    <Typography variant='sb20Bold' className={appointment.status === 'cancelled' || isPastAppointment ? 'fw-bold text-muted' : 'fw-bold'}>
                                      {appointment.therapist_name}
                                    </Typography>
                                    {/* <Typography variant='sb16SemiBold' color={"#4C6EA2"}>{appointment.therapist_role}</Typography> */}
                                  </Grid>
                                  <Grid height={{ xs: "2rem", sm: "4rem" }} className="vertical-line" style={{ background: "#E3DBD5" }} />
                                  <Grid>
                                    <Typography variant='sb20Bold'
                                      className={appointment.status === 'cancelled' || isPastAppointment ? 'fw-bold comfortaa_font text-muted' : 'comfortaa_font fw-bold'}
                                    >
                                      {new Date(appointment.time).toLocaleString()}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid className="vertical-line" style={{ background: "#E3DBD5", height: "1px", width: "100%" }} />
                                <Grid display={'flex'} gap={"20px"} mt={2}>
                                  <Link
                                    href={appointment.status !== 'cancelled' && !isPastAppointment ? appointment.meeting_link : undefined}
                                    className={appointment.status === 'cancelled' || isPastAppointment ? 'fw-bold text-blue text-muted' : 'text-blue fw-bold'}
                                    target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none" }}
                                  >
                                    <Button variant='primary' disabled={isPastAppointment || appointment.status === 'cancelled'}>
                                      <Typography variant='sb16Bolder'
                                        sx={{ textDecoration: isPastAppointment || appointment.status === 'cancelled' ? 'line-through' : 'none' }}>
                                        Meeting link
                                      </Typography>
                                    </Button>
                                  </Link>
                                  {appointment.status === 'booked' && (
                                    <Button
                                      variant='secondary'
                                      sx={{
                                        '&:hover': {
                                          color: 'white !important',
                                          backgroundColor: '#919191 !important'
                                        },
                                        color: '#919191 !important',
                                        border: '1px solid #919191'
                                      }}
                                      onClick={() => handleCancelClick(appointment)}
                                    >
                                      <Typography variant='sb16Bold'>Reschedule or Cancel</Typography>
                                    </Button>
                                  )}
                                  {appointment.status === 'cancelled' && (
                                    <Grid>
                                      <Button variant='secondary'
                                        sx={{ '&:hover': { color: 'white !important', backgroundColor: "#919191" }, color: "#919191 !important", border: "1px solid #919191" }}
                                        onClick={() => openModal(appointment)}
                                      >
                                        <Typography variant='sb16SemiBold'>Cancellation Reason</Typography>
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        )
                      });
                    })}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
        <Modal
          show={modalIsOpen}
          onHide={closeModal}
          contentLabel="Cancellation Reason Modal"
        >
          <Modal.Header closeButton >
            <Typography variant='sb24Bold'>Cancellation Reason</Typography>
          </Modal.Header>
          <Grid padding={"20px"}>
            {selectedAppointment && (
              <Typography variant='body1Regular'>{selectedAppointment.cancellation_reason}</Typography>
            )}
          </Grid>
          <Modal.Footer style={{ borderTop: "none" }}>
            <Button variant="secondary" onClick={closeModal}>
              <Typography variant='sb16Bolder'>Close</Typography>
            </Button>
          </Modal.Footer>
        </Modal>
        <CancelAppointmentModal
          show={showCancelModal}
          handleClose={handleCloseCancelModal}
          handleCancel={(cancellationReason) => handleCancel(selectedAppointment.id, cancellationReason)}
        />
      </Grid>
    </div>
  );
};

const BookTherapy1 = () => (
  <Elements stripe={stripePromise}>
    <BookTherapy />
  </Elements>
);

export default BookTherapy1;
