import React, { useState } from 'react';
import { 
  Grid, 
  Box, 
  Avatar,
  Typography, 
  List, 
  ListItem,
  ListItemIcon, 
  ListItemText,
  IconButton,
  Paper,
  Collapse,
  Tooltip,
  Button
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BusinessIcon from '@mui/icons-material/Business';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/system';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  transition: 'all 0.3s ease'
}));

const CollapsibleButton = styled(Button)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: '#F8FAFC',
  borderRadius: '10px',
  border: '1px solid #E3DBD5',
  '&:hover': {
    backgroundColor: '#F1F5F9',
    border: '1px solid #4C6EA2',
  },
  transition: 'all 0.2s ease',
}));

const CollapsibleMetricsGrid = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const metrics = [
    {
      category: "Session Metrics",
      icon: <AccessTimeIcon />,
      color: '#4C6EA2',
      items: [
        { name: "Session Times", description: "Timestamps of all sessions" },
        { name: "Session Duration", description: "Length of each session" },
        { name: "Session Count", description: "Total number of sessions" }
      ]
    },
    {
      category: "Wellness Metrics",
      icon: <FavoriteIcon />,
      color: '#446494',
      items: [
        { name: "Energy Levels", description: "Scale of 1-5 for each session" },
        { name: "Communication Style", description: "The way they communicated during the session" },
        { name: "Session Topics", description: "Categories of discussion" }
      ]
    },
    {
      category: "Organizational Metrics",
      icon: <BusinessIcon />,
      color: '#3F444B',
      items: [
        { name: "Departments", description: "Department-wise distribution" },
        { name: "Active Users", description: "User participation metrics" },
        { name: "Department Performance", description: "Comparative metrics" }
      ]
    }
  ];

  return (
    <Box>
      {/* Enhanced Header with expand/collapse button */}
      <CollapsibleButton
        onClick={() => setIsExpanded(!isExpanded)}
        disableRipple
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1.5,
          flex: 1
        }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1 
          }}>
            <HelpOutlineIcon sx={{ color: '#4C6EA2' }} />
            <Typography variant="h6" sx={{ 
              color: '#4C6EA2', 
              fontWeight: 500
            }}>
              Available Data Metrics
            </Typography>
          </Box>
          
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#666',
              ml: 2,
              display: { xs: 'none', sm: 'block' }
            }}
          >
            {isExpanded ? "Click to collapse metrics" : "Click to explore available metrics"}
          </Typography>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1
        }}>
          <Typography 
            variant="body2" 
            sx={{ 
              color: '#4C6EA2',
              fontWeight: 500
            }}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </Typography>
          <KeyboardArrowDownIcon 
            sx={{ 
              color: '#4C6EA2',
              transition: 'transform 0.3s ease',
              transform: isExpanded ? 'rotate(180deg)' : 'none'
            }}
          />
        </Box>
      </CollapsibleButton>

      {/* Collapsible content */}
      <Collapse 
        in={isExpanded} 
        timeout="auto"
        sx={{
          mt: 2,
          border: isExpanded ? '1px solid #E3DBD5' : 'none',
          borderRadius: '12px',
          overflow: 'hidden'
        }}
      >
        <Grid container spacing={3} sx={{ p: 2 }}>
          {metrics.map((category) => (
            <Grid item xs={12} md={4} key={category.category}>
              <Box sx={{ 
                backgroundColor: 'white', 
                borderRadius: '12px',
                border: '1px solid #E3DBD5',
                height: '100%'
              }}>
                <Box sx={{ 
                  p: 2, 
                  borderBottom: '1px solid #E3DBD5',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                  <Avatar 
                    sx={{ 
                      bgcolor: category.color,
                      width: 32,
                      height: 32
                    }}
                  >
                    {category.icon}
                  </Avatar>
                  <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {category.category}
                  </Typography>
                </Box>
                <List dense sx={{ p: 0 }}>
                  {category.items.map((item, index) => (
                    <ListItem
                      key={item.name}
                      sx={{
                        borderBottom: index !== category.items.length - 1 ? '1px solid #E3DBD5' : 'none',
                        py: 1.5,
                        px: 2
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        <CircleIcon sx={{ fontSize: 8, color: category.color }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        secondary={item.description}
                        primaryTypographyProps={{
                          variant: 'body2',
                          fontWeight: 500
                        }}
                        secondaryTypographyProps={{
                          variant: 'caption',
                          color: 'text.secondary'
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default CollapsibleMetricsGrid;