// import { Row, Col } from 'react-bootstrap';
import { ReactComponent as IconBlue } from '../data/iconblue.svg';
// import { Link } from "react-router-dom";
// import { Typography, AppBar, Toolbar, Box, Button, Grid, SvgIcon } from "@mui/material";

import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, Typography, IconButton } from '@mui/material';
import { SvgIcon } from '@mui/material';
// import IconBlue from '../data/iconblue.svg'; // İkonunuzu uygun yolu ile içe aktarın

const NotFound404 = () => {
  return (
    <Container 
      maxWidth="md" 
      sx={{ textAlign: 'center', my: 5, overflow: 'hidden' }} 
      className="prevent_overflow"
    >
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={4}>
          <Link to="/">
            <SvgIcon component={IconBlue} inheritViewBox sx={{ width: 200, height: 'auto' }} />
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={10}>
          <Typography variant="h42Bold" sx={{ fontWeight: 'bold' }}>
            Error <span style={{ color: '#5C83BF' }}>404</span>
          </Typography>
          <Typography variant="sb24SemiBold" component="h4" sx={{ mt: 2 }}>
            The page you were looking for doesn't exist.
          </Typography>
          <Typography variant="sb24Medium" component="h5" sx={{ mt: 2 }}>
            You might have typed in the wrong address or the page has moved. In the meantime, try again or 
            <Link to="/" style={{ color: '#5C83BF', textDecoration: 'none' }}> return to the home page.</Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound404;

// const NotFound404 = () => {

//   return (
//     <div className="text-center prevent_overflow" >
//       <Row className="justify-content-center align-items-center text-center my-5">
//         <Col xs={12} md={4}>
//           <Link to="/">
//           <SvgIcon component={IconBlue} inheritViewBox style={{width:"200px", height:"auto"}}/>
//           </Link>
//         </Col>
//       </Row>
//       <Row className="justify-content-center align-items-center">
//         <Col className="px-5">
//           <h1 className="fw-bold">
//             Error <span className="text-blue">404</span>
//           </h1>
//           <h4>The page you were looking for doesn't exist.</h4>
//           <h5>You might have typed in the wrong address or the page has moved. In the meantime, try again or <Link to="/" className="text-blue">return to the home page.</Link></h5>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// export default NotFound404;
