import React from 'react';
import { Button, Typography, Tooltip, CircularProgress } from '@mui/material';

const BookNowButton = ({ 
  therapist, 
  handleBookNow, 
  user, 
  availableTimeSlots, 
  isFutureDate, 
  showDateTime, 
  selectedTherapist 
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const getButtonState = () => {
    const requestsKey = `therapistRequests_${user.uid}`;
    const requests = JSON.parse(localStorage.getItem(requestsKey) || '[]');
    const currentTime = new Date().getTime();
    const updatedRequests = requests.filter(req => currentTime - req.time < 48 * 60 * 60 * 1000);

    if (updatedRequests.length >= 2) {
      const oldestRequestTime = Math.min(...updatedRequests.map(req => req.time));
      const timeLeft = 48 * 60 * 60 * 1000 - (currentTime - oldestRequestTime);
      const hoursLeft = Math.ceil(timeLeft / (60 * 60 * 1000));
      return {
        disabled: true,
        tooltip: `You can request appointments with a maximum of 2 therapists every 48 hours. Please try again in ${hoursLeft} hours.`
      };
    }

    const therapistRequest = updatedRequests.find(req => req.therapistId === therapist.id);
    if (therapistRequest) {
      const timeLeft = 48 * 60 * 60 * 1000 - (currentTime - therapistRequest.time);
      const hoursLeft = Math.ceil(timeLeft / (60 * 60 * 1000));
      return {
        disabled: true,
        tooltip: `You've already requested an appointment with this therapist. You can request again in ${hoursLeft} hours.`
      };
    }

    return {
      disabled: false,
      tooltip: ''
    };
  };

  const { disabled, tooltip } = getButtonState();
  const buttonText = disabled
    ? "Request Sent"
    : availableTimeSlots
        .filter(timeslot => isFutureDate(timeslot.time))
        .some(timeslot => timeslot.therapist_id === therapist.id)
      ? (showDateTime === true && selectedTherapist === therapist.id ? "Book Again" : "Book Now")
      : "Request Appointment";

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await handleBookNow(therapist);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Tooltip title={tooltip} arrow>
      <span>
        <Button
          variant="primary"
          onClick={handleClick}
          disabled={disabled || isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <Typography variant="sb16Bolder">
              {buttonText}
            </Typography>
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

export default BookNowButton;