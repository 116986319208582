// TopicSelection.js
import React, { useState } from 'react';
import { 
  Grid, 
  Typography, 
  Paper,
  Box,
  Chip,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GroupIcon from '@mui/icons-material/Group';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

export const TopicSelection = ({ onTopicSelect }) => {
  const [hoveredTopic, setHoveredTopic] = useState(null);

  const topics = [
    {
      id: 0,
      title: "Conflict Resolution",
      description: "Learn effective strategies to handle team conflicts and build stronger relationships",
      icon: GroupIcon,
      scenarios: 5,
      category: "Team Dynamics",
      tip: "Perfect for improving team harmony"
    },
    {
      id: 1,
      title: "Performance Management",
      description: "Discover ways to nurture talent and support professional growth",
      icon: TrendingUpIcon,
      scenarios: 5,
      category: "Employee Development",
      tip: "Great for developing leadership skills"
    },
    {
      id: 2,
      title: "Resource Management",
      description: "Master the art of balancing resources while maintaining team satisfaction",
      icon: GpsFixedIcon,
      scenarios: 5,
      category: "Strategic Planning",
      tip: "Ideal for strategic decision making"
    }
  ];

  return (
    <Box sx={{ bgcolor: '#FFF7F1', minHeight: '100vh', py: 6 }}>
      <Container maxWidth="lg">

        <Paper 
          elevation={0} 
          sx={{ 
            borderRadius: 4,
            overflow: 'hidden',
            border: '1px solid rgba(92, 131, 191, 0.1)',
            bgcolor: 'white',
            boxShadow: '0 4px 20px rgba(92, 131, 191, 0.08)'
          }}
        >
          {/* Header */}
          <Box 
            sx={{ 
              bgcolor: '#5C83BF', 
              p: 4,
              borderRadius: '20px 20px 0 0',
              background: 'linear-gradient(135deg, #5C83BF 0%, #4C6EA2 100%)'
            }}
          >
            <Typography variant="h4" align="center" sx={{ color: 'white', mb: 1, fontWeight: 600 }}>
              Management Scenarios
            </Typography>
            <Typography variant="subtitle1" align="center" sx={{ color: 'rgba(255, 255, 255, 0.9)' }}>
              Each category offers unique challenges to help you grow as a leader
            </Typography>
          </Box>

          {/* Topics Grid */}
          <Box sx={{ p: 4 }}>
            <Grid container spacing={3}>
              {topics.map((topic) => {
                const TopicIcon = topic.icon;
                return (
                  <Grid item xs={12} md={4} key={topic.id}>
                    <Card 
                      onClick={() => onTopicSelect(topic.id)}
                      onMouseEnter={() => setHoveredTopic(topic.id)}
                      onMouseLeave={() => setHoveredTopic(null)}
                      sx={{
                        height: '100%',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease',
                        borderRadius: 3,
                        border: '1px solid rgba(92, 131, 191, 0.1)',
                        boxShadow: '0 2px 12px rgba(92, 131, 191, 0.05)',
                        '&:hover': {
                          transform: 'translateY(-4px)',
                          boxShadow: '0 8px 24px rgba(92, 131, 191, 0.12)',
                          borderColor: '#5C83BF',
                          '& .explore-text': {
                            color: '#5C83BF',
                            transform: 'translateX(4px)'
                          }
                        }
                      }}
                    >
                      <CardContent sx={{ p: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                          <TopicIcon sx={{ 
                            color: '#4C6EA2', 
                            fontSize: 32,
                            p: 0.8,
                            borderRadius: 2,
                            bgcolor: 'rgba(92, 131, 191, 0.1)'
                          }} />
                          <Chip 
                            label={`${topic.scenarios} scenarios`}
                            size="small"
                            sx={{ 
                              bgcolor: 'rgba(92, 131, 191, 0.08)', 
                              color: '#4C6EA2',
                              borderRadius: '12px',
                              '& .MuiChip-label': {
                                px: 2,
                                py: 0.5,
                                fontWeight: 500
                              }
                            }}
                          />
                        </Box>

                        <Typography 
                          variant="h6" 
                          sx={{ 
                            color: '#4C6EA2',
                            mb: 1.5,
                            fontWeight: 600,
                            fontSize: '1.25rem'
                          }}
                        >
                          {topic.title}
                        </Typography>

                        <Typography 
                          variant="body1" 
                          sx={{ 
                            color: '#718BB1',
                            mb: 2,
                            lineHeight: 1.6
                          }}
                        >
                          {topic.description}
                        </Typography>

                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          mb: 2,
                          p: 1.5,
                          borderRadius: 2,
                          bgcolor: 'rgba(92, 131, 191, 0.05)'
                        }}>
                          <EmojiObjectsIcon sx={{ fontSize: 20, color: '#5C83BF', mr: 1 }} />
                          <Typography variant="body2" sx={{ color: '#5C83BF', fontWeight: 500 }}>
                            {topic.tip}
                          </Typography>
                        </Box>

                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          mt: 2
                        }}>
                          <Typography 
                            variant="button" 
                            className="explore-text"
                            sx={{ 
                              color: '#718BB1',
                              fontWeight: 500,
                              transition: 'all 0.3s ease',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            Start scenario
                            <ArrowForwardIcon sx={{ ml: 1, fontSize: 18 }} />
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Paper>

        {/* Help text */}
        <Box 
          sx={{ 
            mt: 4, 
            p: 2, 
            borderRadius: 3,
            bgcolor: 'rgba(92, 131, 191, 0.05)',
            border: '1px solid rgba(92, 131, 191, 0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1
          }}
        >
          <InfoIcon sx={{ color: '#5C83BF' }} />
          <Typography 
            variant="body1" 
            sx={{ 
              color: '#718BB1',
              fontWeight: 500
            }}
          >
            Select any category to begin exploring scenarios and enhance your management skills
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default TopicSelection;