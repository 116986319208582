import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { BlogData, intros } from '../data/BlogData';
import { Typography, SvgIcon, Button, Grid, Card, CardContent, CardMedia } from "@mui/material";
import { ReactComponent as ArrowBack } from '../data/arrow_back.svg';
import { useMediaQuery } from 'react-responsive';
import ReactDOMServer from 'react-dom/server';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import "./Blogs.scss";

// Utility function to split the content by paragraphs
const splitContentByH2 = (content) => {
  // Convert JSX content to string
  const contentStr = ReactDOMServer.renderToStaticMarkup(content);

  // Remove the first <h2> tag and its content
  const contentWithoutFirstH2 = contentStr.replace(/<h1[^>]*>.*?<\/h1>/, '');

  // Split by <p> tags
  const paragraphs = contentWithoutFirstH2.split(/<\/p>/).map((paragraph, index) => {
    if (index === contentWithoutFirstH2.split(/<\/p>/).length - 1) return paragraph; // last paragraph without re-adding <p> tag
    return paragraph + '</p>'; // add back </p> tag to the split paragraphs
  }).filter(paragraph => paragraph.trim() !== ''); // remove empty strings

  // Group paragraphs into sections of 3
  const sections = [];
  for (let i = 0; i < paragraphs.length; i += 3) {
    const section = paragraphs.slice(i, i + 3);
    if (section.length > 0) {
      sections.push(section.join(''));
    }
  }

  return sections;
};


const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [intro, setIntro] = useState(null);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { topic, articleId } = useParams();
  const location = useLocation();


  const tags = ["Journaling The Journey Within", "From A Powerful Adversary to A Valuable Ally - How to Transform Anxiety", "Own Your Rhythm: A Guide To Workplace Well-Being For Young Professionals"];

  useEffect(() => {
    setBlogs(BlogData);

    if (!isInitialized) {
      if (topic) {
        handleTagClick(decodeURIComponent(topic), false);
      } else {
        const randomTagIndex = Math.floor(Math.random() * tags.length);
        const randomTag = tags[randomTagIndex];
        handleTagClick(randomTag, false);
      }

      if (articleId) {
        const selected = BlogData.find(blog => blog.id === decodeURIComponent(articleId));
        if (selected) {
          setSelectedArticle(selected);
        }
      }

      setIsInitialized(true);
    }

  }, [location, isInitialized]);
  
  const handleTagClick = (tag, updateUrl = true) => {
    setSelectedTag(tag);
    setIntro(intros.find(intro => intro.id === tag).content);
    setSelectedArticle(null);
    setCurrentSectionIndex(0);
    if (updateUrl) {
      navigate(`/blogs?topic=${encodeURIComponent(tag)}`);
    }
  };

  const relatedArticles = blogs
    .filter(blog => blog.tags.includes(selectedTag))
    .filter(blog => !selectedArticle || blog.id !== selectedArticle.id);

  const handleNextArticle = () => {
    const currentTopicArticles = blogs.filter(blog => blog.tags.includes(selectedTag));
    const currentTopicIndex = tags.indexOf(selectedTag);

    if (currentTopicArticles.length > 0) {
      const currentIndex = currentTopicArticles.findIndex(article => article.id === selectedArticle?.id);

      if (currentIndex === -1 || currentIndex === currentTopicArticles.length - 1) {
        const nextTopicIndex = (currentTopicIndex + 1) % tags.length;
        const nextTopic = tags[nextTopicIndex];
        handleTagClick(nextTopic);
      } else {
        setSelectedArticle(currentTopicArticles[currentIndex + 1]);
        navigate(`/blogs?topic=${encodeURIComponent(selectedTag)}&articleId=${currentTopicArticles[currentIndex + 1].id}`);
      }
    } else {
      const nextTopicIndex = (currentTopicIndex + 1) % tags.length;
      const nextTopic = tags[nextTopicIndex];
      handleTagClick(nextTopic);
    }
    setCurrentSectionIndex(0);
  };

  const handleBackArticle = () => {
    const currentTopicArticles = blogs.filter(blog => blog.tags.includes(selectedTag));
    const currentTopicIndex = tags.indexOf(selectedTag);

    if (currentTopicArticles.length > 0) {
      const currentIndex = currentTopicArticles.findIndex(article => article.id === selectedArticle?.id);

      if (currentIndex === -1 || currentIndex === 0) {
        const previousTopicIndex = (currentTopicIndex - 1 + tags.length) % tags.length;
        const previousTopic = tags[previousTopicIndex];
        handleTagClick(previousTopic);
      } else {
        setSelectedArticle(currentTopicArticles[currentIndex - 1]);
        navigate(`/blogs?topic=${encodeURIComponent(selectedTag)}&articleId=${currentTopicArticles[currentIndex - 1].id}`);
      }
    } else {
      const previousTopicIndex = (currentTopicIndex - 1 + tags.length) % tags.length;
      const previousTopic = tags[previousTopicIndex];
      handleTagClick(previousTopic);
    }
    setCurrentSectionIndex(0);
  };

  const handleNextSection = () => {
    setCurrentSectionIndex(prevIndex => prevIndex + 1);
  };

  const handlePreviousSection = () => {
    setCurrentSectionIndex(prevIndex => prevIndex - 1);
  };
  const gridRef = useRef(null);
  const [showButtons, setShowButtons] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowButtons(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );

    if (gridRef.current) {
      observer.observe(gridRef.current);
    }

    return () => {
      if (gridRef.current) {
        observer.unobserve(gridRef.current);
      }
    };
  }, []);


  return (
    <div className="prevent_overflow">
      <Grid container my={3} className="content">
      {selectedTag && (
          <Grid sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              {selectedArticle && (
                  <Grid sx={{ marginLeft: { xs: "5px",  sm: "20px", md: "50px"  } }}> 
                      <Button onClick={handleBackArticle} style={{ textDecoration: "none", display: "flex", alignItems: "end" }}>
                          {isMobile ? (
                              <Typography color={"primary.darkerBlue"} variant='sb16Bold' textAlign={'start'}>
                                  Back
                              </Typography>
                          ) : (
                              <>
                                <SvgIcon component={ArrowBack}/> 
                                <Typography sx={{ display: { xs: "none", sm: "block" } }} color={"primary.darkerBlue"} variant='sb20Bold' textAlign={'start'}>
                                    Go Back
                                </Typography>
                              </>
                          )}
                      </Button>
                  </Grid>
              )}
              <Grid sx={{ marginRight: { xs: "5px", sm: "30px", md: "50px" } }}>
                  {relatedArticles.length > 0 && selectedArticle && (
                      <Button onClick={handleNextArticle}>
                          <Typography color={"primary.darkerBlue"} variant={isMobile ? 'sb16Bold' : 'sb20Bold'} textAlign={'start'}>
                              Next Article
                          </Typography>
                      </Button>
                  )}
              </Grid>
          </Grid>
        )}
        <Grid container gap={5} my={3} alignItems={'center'} justifyContent={'center'}>
          {selectedTag === null ? (
            <Grid xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ filter: "drop-shadow(0px 5px 10px #EFE5DE)" }}>
              <Grid xl={6} lg={8} md={8} sm={10} xs={11}>
                <Grid width={"100%"} sx={{ backgroundColor: "#5C83BF", padding: "16px", borderRadius: "10px 10px 0px 0px" }}>
                  <Typography variant='sb24Bold' color={'white'}>Pick a topic you want to read about</Typography>
                </Grid>
                <Grid boxShadow={"0px 5px 10px #EFE5DE"} bgcolor={'white'} borderRadius={"0px 0px 10px 10px"} padding={"40px"} gap={"20px"} display={'flex'} flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'center'}>
                  {tags.map((tag, index) => (
                    <Button key={index} onClick={() => handleTagClick(tag)} sx={{ width: "100%", borderRadius: "40px", border: "2px solid #4C6EA2", padding: "8px", background: "rgba(92, 131, 191, 0.1)" }}>
                      <Typography variant='sb16Bolder' color={"primary.darkerBlue"}>
                        {tag}
                      </Typography>
                    </Button>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            selectedArticle === null ? (
              <>
                <Grid mb={5} xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{ filter: "drop-shadow(0px 5px 10px #EFE5DE)" }}>
                  <Grid xl={6} lg={8} md={8} sm={10} xs={11}>
                    <Grid width={"100%"} sx={{ backgroundColor: "#5C83BF", padding: "16px", borderRadius: "10px 10px 0px 0px" }}>
                      <Typography variant='sb24Bold' color={'white'}>Pick a topic you want to read about</Typography>
                    </Grid>
                    <Grid boxShadow={"0px 5px 10px #EFE5DE"} bgcolor={'white'} padding={"40px 40px 20px 40px"} gap={"40px"} display={'flex'} flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'center'}>
                      <Grid display={'flex'} flexDirection={'row'} gap={"20px"} flexWrap={'wrap'} alignItems={'center'} justifyContent={'center'}>
                        {tags.map((tag, index) => (
                          <Button
                            key={index}
                            onClick={() => handleTagClick(tag)}
                            sx={{
                              width: "100%",
                              borderRadius: "40px",
                              border: "2px solid #4C6EA2",
                              padding: "12px 8px 12px 8px",
                              background: selectedTag === tag ? "#4C6EA2" : "rgba(92, 131, 191, 0.1)",
                              color: selectedTag === tag ? "white" : "primary.darkerBlue",
                              "&:hover": {
                                color: selectedTag === tag ? "primary.darkerBlue" : null
                              }
                            }}
                          >
                            <Typography variant='sb16Bolder'>
                              {tag}
                            </Typography>
                          </Button>
                        ))}
                      </Grid>
                      <Grid bgcolor={'white'} borderRadius={"0px 0px 10px 10px"} gap={"20px"} display={'flex'} flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'center'}>
                        <Typography variant='sb16Bold' color={"#4C6481"}>{intro}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'} textAlign={'center'} mx={2}>
                  <Typography variant='h32Bold' color={"#4C6EA2"}>
                    Articles about {selectedTag}
                  </Typography>
                </Grid>
                {blogs.filter(blog => blog.tags.includes(selectedTag)).length === 0 ? (
                  <Typography variant="h32Bold" align="center">There is no article for now</Typography>
                ) : (
                  blogs.filter(blog => blog.tags.includes(selectedTag)).map((blog) => (
                    <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} mb={4}
                      xl={3}
                      lg={3}
                      md={5}
                      sm={10}
                      xs={12}
                      key={blog.id}
                    >
                      <Card className="blog-card">
                        <CardMedia sx={{ borderRadius: "10px 10px 0px 0px" }} height={"275px"} component="img" image={blog.imageUrl} alt="blog_image" onClick={() => setSelectedArticle(blog)} />
                        <Grid gap={"20px"} padding={"24px"} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                          <CardContent >
                            <Typography variant='sb24Bold' color="otherColors.mainBodyDark">{blog.title}</Typography>
                            <div className='horizontal-line' style={{ height: "1px", backgroundColor: "#E5E5E5", marginBottom: "20px" }}></div>
                            <Typography variant='sb16SemiBold'>
                              {blog.shortIntro}
                            </Typography>
                          </CardContent>
                          <Button
                            sx={{
                              width: "80%",
                              gap: "12px",
                              borderRadius: "40px",
                              padding: "10px",
                              backgroundColor: "#5C83BF",
                              color: "white",
                              border: "1px solid #5C83BF",
                              transition: "background-color 0.3s, border 0.3s, color 0.3s",
                              "&:hover": {
                                backgroundColor: "white",
                                color: "#5C83BF"
                              }
                            }}
                            onClick={() => {
                              setSelectedArticle(blog); 
                              setCurrentSectionIndex(0);
                              navigate(`/blogs?topic=${encodeURIComponent(selectedTag)}&articleId=${encodeURIComponent(blog.id)}`);
                              window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
                            }}
                          >
                            <Typography variant='sb16Bolder'>Go to Article</Typography>
                          </Button>
                        </Grid>
                      </Card>
                    </Grid>
                  ))
                )}
              </>
            ) : (
              <>
                <Grid container justifyContent={'center'} flexDirection={'column'} alignItems={'center'} gap={"20px"}>
                  {selectedArticle && (
                    <Grid container spacing={2} justifyContent={'center'}>
                      <Grid item xs={12} textAlign={'center'} sx={{ m: 3 }}>
                        <Typography variant="h42SemiBold" color="otherColors.mainBodyDark" component="div">{selectedArticle.title}</Typography>
                      </Grid>
                      <Grid item xs={10} sm={9} md={10} lg={9} xl={8} style={{ position: 'relative' }} ref={gridRef}>
      <Card boxShadow={"0px 10px 20px #E9E2DD"} sx={{ marginBottom: '20px', padding:{xs:"5px 10px 5px 10px", sm:"15px 25px 15px 25px"} ,color: "#3F444B"}}>
        <CardContent>
          <div dangerouslySetInnerHTML={{ __html: splitContentByH2(selectedArticle.content)[currentSectionIndex] }} />
        </CardContent>
      </Card>
      <>
        <Button
          sx={{ 
            position: {xs: showButtons ? 'fixed' : "absolute", sm:"absolute"}, 
            left: {xs:"-10px", sm:'-55px'}, 
            top: '50%', 
            transform: 'translateY(-50%)',
            // display: showButtons ? 'block' : 'none'
          }}
          onClick={() => {
            handlePreviousSection();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={currentSectionIndex === 0}
        >
          <SvgIcon sx={{ fontSize: isMobile ? '40px' : '50px' }} component={ArrowCircleLeftIcon} />
        </Button>
        <Button
          sx={{ 
            position: {xs: showButtons ? 'fixed' : "absolute", sm:"absolute"}, 
            right: {xs:"-10px", sm:'-70px'}, 
            top: '50%', 
            transform: 'translateY(-50%)',
            // display: showButtons ? 'block' : 'none'
          }}
          onClick={() => {
            handleNextSection();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          disabled={currentSectionIndex === splitContentByH2(selectedArticle.content).length - 1}
        >
          <SvgIcon sx={{ fontSize: isMobile ? '40px' : '50px' }} component={ArrowCircleRightIcon} />
        </Button>
      </>
    </Grid>
                    </Grid>
                  )}
                  <Grid mx={5}>
                    <Typography variant='sb24Bold' display={'flex'} alignItems={'center'} gap={"10px"} color="otherColors.mainBodyDark">
                      Topics in this article:
                      {selectedArticle && selectedArticle.tags.map((tag, i) => (
                        <Grid key={i} sx={{ width: "fit-content", borderRadius: "40px", border: "2px solid #4C6EA2", padding: "12px", background: "rgba(92, 131, 191, 0.1)", display: "flex", alignItems: "center" }}>
                          <Typography variant='sb16Bolder' color={"primary.darkerBlue"}>
                            {tag}
                          </Typography>
                        </Grid>
                      ))}
                    </Typography>
                  </Grid>
                  {relatedArticles.length > 0 && (
                    <Grid container mt={10} justifyContent={'center'}>
                      <Grid xs={12} textAlign={'center'} display={'flex'} justifyContent={'center'} mb={5}>
                        <Typography textAlign={'center'} variant='h32Bold' color="otherColors.mainBodyDark">Related Articles for you:</Typography>
                      </Grid>
                      {relatedArticles.map((blog) => (
                        <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} mb={4}
                          xl={3}
                          lg={3}
                          md={5}
                          sm={10}
                          xs={12}
                          key={blog.id}
                        >
                          <Card className="blog-card">
                            <CardMedia sx={{ borderRadius: "10px 10px 0px 0px" }} height={"275px"} component="img" image={blog.imageUrl} alt="blog_image" onClick={() => setSelectedArticle(blog)} />
                            <Grid gap={"20px"} padding={"24px"} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                              <CardContent>
                                <Typography variant='sb24Bold'>{blog.title}</Typography>
                                <div className='horizontal-line' style={{ height: "1px", backgroundColor: "#E5E5E5", marginBottom: "20px" }}></div>
                                <Typography variant='sb16SemiBold'>
                                  {blog.shortIntro}
                                </Typography>
                              </CardContent>
                              <Button
                                sx={{
                                  width: "80%",
                                  gap: "12px",
                                  borderRadius: "40px",
                                  padding: "10px",
                                  backgroundColor: "#5C83BF",
                                  color: "white",
                                  border: "1px solid #5C83BF",
                                  transition: "background-color 0.3s, border 0.3s, color 0.3s",
                                  "&:hover": {
                                    backgroundColor: "white",
                                    color: "#5C83BF"
                                  }
                                }}
                                onClick={() => {
                                  setSelectedArticle(blog); 
                                  setCurrentSectionIndex(0);
                                  window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
                                }}
                              >
                                <Typography variant='sb16Bolder'>Go to Article</Typography>
                              </Button>
                            </Grid>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </>
            )
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Blogs;
