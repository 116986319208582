import React, { useState, useEffect } from 'react';
import { Alert, Snackbar, Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const SlackSuccessNotification = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const location = useLocation();

  useEffect(() => {
    console.log('Location state:', location.state); // Debug log
    
    if (location.state?.slackSuccess) {
      console.log('Setting success message:', location.state.message); // Debug log
      setMessage(location.state.message || 'Slack integration successful!');
      setOpen(true);
    }
  }, [location]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert 
        onClose={handleClose} 
        severity="success" 
        sx={{ 
          width: '100%',
          backgroundColor: '#5C83BF',
          color: 'white',
          '& .MuiAlert-icon': {
            color: 'white'
          }
        }}
      >
        <Box>
          <Typography variant="subtitle1" component="div" fontWeight="bold">
            Success! 🎉
          </Typography>
          <Typography variant="body2">
            {message}
          </Typography>
        </Box>
      </Alert>
    </Snackbar>
  );
};

export default SlackSuccessNotification;