import React, { useEffect, useRef } from 'react';
import { executeGraphCode, destroyChart } from './graphUtils';

const DynamicChart = ({ graphCode, data, loading }) => {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  useEffect(() => {
    const initializeChart = () => {
      if (!loading && graphCode && canvasRef.current) {
        // Clean up any existing chart
        if (chartRef.current) {
          destroyChart(chartRef.current);
          chartRef.current = null;
        }

        try {
          // Create new chart
          const canvas = canvasRef.current;
          // Set canvas dimensions
          canvas.style.width = '100%';
          canvas.style.height = '100%';
          canvas.width = canvas.offsetWidth;
          canvas.height = canvas.offsetHeight;

          chartRef.current = executeGraphCode(graphCode, data, canvas);
        } catch (error) {
          console.error('Error executing chart code:', error);
        }
      }
    };

    initializeChart();

    // Cleanup function
    return () => {
      if (chartRef.current) {
        destroyChart(chartRef.current);
        chartRef.current = null;
      }
    };
  }, [graphCode, data, loading]);

  return (
    <div className="relative w-full h-full" style={{ height: '400px', padding: '20px' }}>
      <canvas 
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    </div>
  );
};

export default DynamicChart;