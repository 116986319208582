import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { ReactComponent as FailureIcon } from '../assets/failure-icon.svg'; // #TODO add icon
import './style.scss';

const FailurePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    //remove session storages of any checkout info progress
    sessionStorage.removeItem('checkoutInProgress')
    sessionStorage.removeItem('timeslotId')
    sessionStorage.removeItem('therapistId')
    
    const timer = setTimeout(() => {
      navigate('/book-therapist');
    }, 5000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="prevent_overflow">
      <div className="failure-page d-flex flex-column align-items-center justify-content-center content">
       {/* <FailureIcon className="failure-icon mb-4" />  */}
        <h1 className="text-danger fw-bold">Payment Failed</h1>
        <h4 className="text-center">
          There was a problem processing your payment. No charge has been made. Please try again or contact support.
        </h4>
        <h5 className="text-center">
          You will be redirected back to the booking page shortly to retry.
        </h5>
      </div>
    </div>
  );
};

export default FailurePage;