import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from './contexts/UserContext';

const ProtectedSlackAuthRoute = () => {
  const { user, loading } = useUser();
  
  // Show loading state while checking authentication
  if (loading) {
    return null;
  }

  // Allow access to slack auth routes even if user is not authenticated
  // This is necessary because the Slack OAuth flow may happen before user login
  return <Outlet />;
};

export default ProtectedSlackAuthRoute;