import React, { useState, useEffect } from 'react';
import { Row, Col, Badge, Toast } from 'react-bootstrap';
import { useUser } from '../contexts/UserContext';
import { URL } from '../Helpers';
import LoadingSpinner from './shared/LoadingSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { db, doc, onSnapshot } from "../Firebase"; // Updated imports
import './style.scss';
import { Box, Button, Grid, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as TherapistsIcon } from '../data/psychology.svg';
import { ReactComponent as AppointmentsIcon } from '../data/appointments.svg';
import { useMediaQuery } from 'react-responsive';
import Appointments from './TherapistView';
import MyAvatar from "../data/appointments_avatar.png"

const EditTherapistProfile = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(true)
  const [loadingText,setLoadingText] =  useState("")
  const [therapistInfo, setTherapistInfo] = useState();
  const [originalTherapistInfo, setOriginalTherapistInfo] = useState();
  const [editedName, setEditedName] = useState('');
  const [editedAbout, setEditedAbout] = useState('');
  const [editedTitle, setEditedTitle] = useState('');
  const [editedBio, setEditedBio] = useState('');
  const [editedApproach, setEditedApproach] = useState('');
  const [editedAreas, setEditedAreas] = useState([]);
  const [editedLanguages, setEditedLanguages] = useState([]);
  const [editedExperiences, setEditedExperiences] = useState([]);
  const [editedEducations, setEditedEducations] = useState([]);
  const [editNameMode, setEditNameMode] = useState(false);
  const [editAboutMode, setEditAboutMode] = useState(false);
  const [editApproachMode, setEditApproachMode] = useState(false);
  const [editTitleMode, setEditTitleMode] = useState(false);
  const [editBioMode, setEditBioMode] = useState(false);
  const [editAreaMode, setEditAreaMode] = useState(false);
  const [editLanguageMode, setEditLanguageMode] = useState(false);
  const [editExperienceMode, setEditExperienceMode] = useState(false);
  const [editEducationMode, setEditEducationMode] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [aboutWordCount, setAboutWordCount] = useState(0);
  const [approachWordCount, setApproachWordCount] = useState(0);
  const [bioWordCount, setBioWordCount] = useState(0);
  const [toastType, setToastType] = useState('success');
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [view, setView] = useState("profile");
  const isSmallScreen = useMediaQuery({ query: '(max-width: 500px)' });
  const isMediumScreen = useMediaQuery({ query: '(max-width: 900px)' });

  const fetchTherapist = (async () => {
    try {
      const response = await fetch(`${URL}/therapists_timeslots/${user.uid}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.therapistInfo) {
        setTherapistInfo(data.therapistInfo);
        setOriginalTherapistInfo(data.therapistInfo);
        setEditedName(data.therapistInfo.name);
        setEditedAbout(data.therapistInfo.long_bio);
        setEditedTitle(data.therapistInfo.therapist_title);
        setEditedBio(data.therapistInfo.bio);
        setEditedApproach(data.therapistInfo.therapist_approach);
        setEditedAreas(data.therapistInfo.areas);
        setEditedLanguages(data.therapistInfo.languages);
        setEditedEducations(data.therapistInfo.educations);
        setEditedExperiences(data.therapistInfo.experiences);
        setBioWordCount(countWords(data.therapistInfo.bio));
        setAboutWordCount(countWords(data.therapistInfo.long_bio));
        setApproachWordCount(countWords(data.therapistInfo.therapist_approach));

        setLoading(false)
      } else {
        console.log("Therapist document does not exist.");
      }
    } catch (error) {
        console.error("Error fetching therapist profile:", error);
    }
  });

  useEffect(() => {
    if (user) fetchTherapist();
  }, [])

  useEffect(() => {
    if (user && user.uid) {
      const storedStatus = sessionStorage.getItem(`onboardingStatus-${user.uid}`);
      if (storedStatus !== null) {
        setOnboardingComplete(storedStatus === 'true');
      }

      const unsubscribe = onSnapshot(doc(db, "therapists", user.uid), (doc) => {
        const data = doc.data();
        if (data) {
          const isComplete = !!data.onboarding_complete;
          setOnboardingComplete(isComplete);
          // Update session storage
          sessionStorage.setItem(`onboardingStatus-${user.uid}`, isComplete.toString());
        }
      }, (error) => {
        console.error("Error listening to onboarding_complete changes:", error);
      });

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {   
      const response = await fetch(`${URL}/edit_therapist_profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },

        body: JSON.stringify({
            name: editedName,
            long_bio: editedAbout,
            therapist_title: editedTitle,
            bio: editedBio,
            therapist_approach: editedApproach,
            educations: editedEducations,
            experiences: editedExperiences,
            areas: editedAreas,
            languages: editedLanguages,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

    } catch (error) {
      console.error("Error updating therapist information:", error);
      setToastMessage(`Update failed: ${error.message}`);
      setToastType('error');
      setShowToast(true);
    }
    
  };

  const handleCancel = () => {
    if (originalTherapistInfo) {
      setEditedName(originalTherapistInfo.name);
      setEditedAbout(originalTherapistInfo.long_bio);
      setEditedTitle(originalTherapistInfo.therapist_title);
      setEditedBio(originalTherapistInfo.bio);
      setEditedApproach(originalTherapistInfo.therapist_approach);
      setEditedAreas(originalTherapistInfo.areas);
      setEditedLanguages(originalTherapistInfo.languages);
      setEditedEducations(originalTherapistInfo.educations);
      setEditedExperiences(originalTherapistInfo.experiences);
    }

    setEditNameMode(false);
    setEditAboutMode(false);
    setEditTitleMode(false);
    setEditBioMode(false);
    setEditApproachMode(false);
    setEditAreaMode(false);
    setEditLanguageMode(false);
    setEditEducationMode(false);
    setEditExperienceMode(false);
};

  // A function to call /api/start-onboarding/<therapistID> to start the onboarding to add their bank account response is a link that the user should be redirected to
  const handleOnboarding = async (e) => {
    e.preventDefault();
    setLoading(true)
    setLoadingText("Redirecting to stripe Connect")
    try {
      const response = await fetch(`${URL}/start-onboarding/${user.uid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.accessToken}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      
      if (data.url) {
        console.log(data.url)
        // Redirect the user to the Stripe onboarding link
        window.location.href = data.url;
      } else {
        setLoading(false)
        // Handle the scenario where the URL is not provided
        console.error('No onboarding URL returned from the API.');
        setToastMessage('No onboarding URL returned from the API.');
        setToastType('error');
        setShowToast(true);
        
      }
    } catch (error) {
      setLoading(false)
      console.error('Failed to start onboarding:', error);
      setToastMessage('No onboarding URL returned from the API.');
      setToastType('error');
      setShowToast(true);
      // Here you would normally handle the error, perhaps showing a message to the user
    }
  };
  
  const countWords = (text) => {
    return text?.trim().split(/\s+/).filter(word => word.length > 0).length || 0;
  };

  const handleNameChange = (e) => {
    const text = e.target.value;
    setEditedName(text);
  };

  const handleAboutChange = (e) => {
    const text = e.target.value;
    setEditedAbout(text);
    setAboutWordCount(countWords(text));
  };

  const handleApproachChange = (e) => {
    const text = e.target.value;
    setEditedApproach(text);
    setApproachWordCount(countWords(text));
  };

  const handleNameSubmit = async (e) => {
    e.preventDefault();
    if (!editedName.trim()) {
        setToastMessage('Name cannot be empty.');
        setToastType('error');
        setShowToast(true);
        return;
    }
    try {
        await handleSubmit(e);

        setToastMessage('Name updated successfully!');
        setToastType('success');
        setShowToast(true);  

        await fetchTherapist();

        } catch (error) {
        console.error("Error updating name:", error);
        } finally {

        setEditNameMode(false);
    }
  };

  const handleAboutSubmit = async (e) => {
    e.preventDefault();
    if (!editedAbout.trim() || aboutWordCount> 200) {
        setToastMessage(!editedAbout.trim() ? 'About section cannot be empty.' : 'About section must be 200 words or less.');
        setToastType('error');
        setShowToast(true);
        return;
    }
    try {
        await handleSubmit(e);

        setToastMessage('About section updated successfully!');
        setToastType('success');
        setShowToast(true);

        await fetchTherapist();

        } catch (error) {
        console.error("Error updating about section:", error);
        } finally {

        setEditAboutMode(false);
    }
  };

  const handleApproachSubmit = async (e) => {
    e.preventDefault();
    if (!editedApproach.trim() || approachWordCount > 200) {
        setToastMessage(!editedApproach.trim() ? 'Approach cannot be empty.' : 'Approach section must be 200 words or less.');
        setToastType('error');
        setShowToast(true);
        return;
    }

    try {
        await handleSubmit(e);

        setToastMessage('Approach section updated successfully!');
        setToastType('success');
        setShowToast(true);

        await fetchTherapist();

        } catch (error) {
        console.error("Error updating approach section:", error);
        } finally {

        setEditApproachMode(false);
    }
  };

  const handleBioChange = (e) => {
    const text = e.target.value;
    setEditedBio(text);
    setBioWordCount(countWords(text));
  };

  const handleBioSubmit = async (e) => {
    e.preventDefault();
    if (!editedBio.trim() || bioWordCount > 20) {
        setToastMessage(!editedBio.trim() ? 'Biography cannot be empty.' : 'Biography must be 20 words or less.');
        setToastType('error');
        setShowToast(true);
        return;
    }
    try {
      await handleSubmit(e);

      setToastMessage('Biography section updated successfully!');
      setToastType('success');
      setShowToast(true);

      await fetchTherapist();

    } catch (error) {
      console.error("Error updating biography section:", error);
    } finally {
      setEditBioMode(false);
    }
  };

  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleTitleSubmit = async (e) => {
    e.preventDefault();
    if (!editedTitle.trim()) {
        setToastMessage('Title cannot be empty.');
        setToastType('error');
        setShowToast(true);
        return;
    }

    try {
      await handleSubmit(e);

      setToastMessage('Title updated successfully!');
      setToastType('success');
      setShowToast(true);

      await fetchTherapist();

    } catch (error) {
      console.error("Error updating title:", error);
    } finally {
      setEditTitleMode(false);
    }
  };
 
  const handleAreaChange = (index, value) => {
    const updatedAreas = [...editedAreas];
    updatedAreas[index] = value;
    setEditedAreas(updatedAreas);
  };

  const handleAreaSubmit = async (e) => {
    e.preventDefault();
    if (!Array.isArray(editedAreas) || editedAreas.length === 0 || editedAreas.some(area => !area.trim())) {
        setToastMessage('Interests cannot be empty.');
        setToastType('error');
        setShowToast(true);
        return;
    }

    try {
        await handleSubmit(e);
  
        setToastMessage('Interests updated successfully!');
        setToastType('success');
        setShowToast(true);
  
        await fetchTherapist();
  
      } catch (error) {
        console.error("Error updating area:", error);
      } finally {
        setEditAreaMode(false);
      }
  };
 
  const handleLanguageChange = (index, value) => {
    const updatedLanguages = [...editedLanguages];
    updatedLanguages[index] = value;
    setEditedLanguages(updatedLanguages);
  };

  const handleLanguageSubmit = async (e) => {
    e.preventDefault();
    if (!Array.isArray(editedLanguages) || editedLanguages.length === 0 || editedLanguages.some(language => !language.trim())) {
        setToastMessage('Languages cannot be empty.');
        setToastType('error');
        setShowToast(true);
        return;
    }
    try {
        await handleSubmit(e);
  
        setToastMessage('Languages updated successfully!');
        setToastType('success');
        setShowToast(true);
  
        await fetchTherapist();
  
      } catch (error) {
        console.error("Error updating languages:", error);
      } finally {
        setEditLanguageMode(false);
      }
  };

  const handleEducationChange = (index, value) => {
    const updatedEducations = [...editedEducations];
    updatedEducations[index] = value;
    setEditedEducations(updatedEducations);
  };

  const handleEducationSubmit = async (e) => {
    e.preventDefault();
    if (!Array.isArray(editedEducations) || editedEducations.length === 0 || editedEducations.some(edu => !edu.trim())) {
        setToastMessage('Education details cannot be empty.');
        setToastType('error');
        setShowToast(true);
        return;
    }

    try {
        await handleSubmit(e);
  
        setToastMessage('Education details updated successfully!');
        setToastType('success');
        setShowToast(true);
  
        await fetchTherapist();
  
      } catch (error) {
        console.error("Error updating education:", error);
      } finally {
        setEditEducationMode(false);
      }
  };
 
  const handleExperienceChange = (index, value) => {
    const updatedExperiences = [...editedExperiences];
    updatedExperiences[index] = value;
    setEditedExperiences(updatedExperiences);
  };

  const handleExperienceSubmit = async (e) => {
    e.preventDefault();
    if (!Array.isArray(editedExperiences) || editedExperiences.length === 0 || editedExperiences.some(exp => !exp.trim())) {
        setToastMessage('Experiences cannot be empty.');
        setToastType('error');
        setShowToast(true);
        return;
    }

    try {
        await handleSubmit(e);
  
        setToastMessage('Experiences updated successfully!');
        setToastType('success');
        setShowToast(true);
  
        await fetchTherapist();
  
      } catch (error) {
        console.error("Error updating experiences:", error);
      } finally {
        setEditExperienceMode(false);
      }
  };

  const handleAddEducation = () => {
    const educations = Array.isArray(editedEducations) ? editedEducations : [];

    if (educations.length >= 3) {
        setToastMessage('You can only add up to 3 education details.');
        setToastType('error');
        setShowToast(true);
        return;
    }

    setEditedEducations([...educations, '']); 

  };
    
  const handleDeleteEducation = (index) => {
    const updatedEducations = editedEducations.filter((_, i) => i !== index);
    setEditedEducations(updatedEducations);
  };

  const handleAddExperience = () => {
    const experiences = Array.isArray(editedExperiences) ? editedExperiences : [];

    if (experiences.length >= 3) {
        setToastMessage('You can only add up to 3 experiences.');
        setToastType('error');
        setShowToast(true);
        return;
    }

    setEditedExperiences([...experiences, '']); 
  };  
  
  const handleDeleteExperience = (index) => {
    const updatedExperiences = editedExperiences.filter((_, i) => i !== index);
    setEditedExperiences(updatedExperiences);
  };
  
  const handleAddArea = () => {
    const areas = Array.isArray(editedAreas) ? editedAreas : [];

    if (areas.length >= 4) {
        setToastMessage('You can only add up to 4 interests.');
        setToastType('error');
        setShowToast(true);
        return;
    }

    setEditedAreas([...areas, '']); 
  };
  
  const handleDeleteArea = (index) => {
    const updatedAreas = editedAreas.filter((_, i) => i !== index);
    setEditedAreas(updatedAreas);
  };

  const handleAddLanguage = () => {
    setEditedLanguages(prevLanguages => Array.isArray(prevLanguages) ? [...prevLanguages, ''] : ['']);
  };
  
  const handleDeleteLanguage = (index) => {
    const updatedLanguages = editedLanguages.filter((_, i) => i !== index);
    setEditedLanguages(updatedLanguages);
  };
  
  return (
    <div className="prevent_overflow">
      <Toast 
        onClose={() => setShowToast(false)} 
        show={showToast} delay={3000} 
        className={`custom_toast ${toastType === 'success' ? 'toast-success' : 'toast-error'}`}
        autohide  
      >
        <h6 className="text-white fw-bold mt-2">{toastMessage}</h6>
      </Toast>
      {loading ? (
        <LoadingSpinner text={loadingText} className="bigger_height" />
      ) : (
        view === 'appointments' ? (
            <Appointments/>
        ) : (
          <Grid container flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
          <Grid display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'} gap={"60px"} mt={4}>
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            {view === "therapists" ? (
              <>
                <Button sx={{color:"secondary.main"}}>
                  <Box   
                    sx={{
                      bgcolor: "primary.darkerBlue",
                      width: "42px",
                      height: "42px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center", 
                      alignItems: "center",
                    }}
                  >
                    <SvgIcon component={TherapistsIcon} width="20px" height="20px"/>
                  </Box>
                  <Typography variant={isSmallScreen ? 'sb16Bolder' : 'sb24Bolder'}  sx={{color: "primary.darkerBlue"}}>My Profile</Typography>
                </Button>
                <div className="vertical-line" style={{background:"#E3DBD5", height:"2rem"}} />
                <Button onClick={() => setView("appointments")}>
                  <SvgIcon component={AppointmentsIcon} width="32px" height="32px"/>
                  <Typography variant={isSmallScreen ? 'sb16Bold' : 'sb24Bold'} sx={{color: "primary.darkerBlue"}}>My Appointments</Typography>
                </Button>
              </>
            ) : (
              <Grid display={'flex'} flexDirection={{xs:'column', md:'row'}} justifyContent={'center'} alignItems={'center'} gap={{xs:'30px', md:'10px'}}>
                <Grid position={'relative'}>
                  <Box position={'absolute'} left={{xs:'-23px', md:'-55px'}} zIndex={"2"} width={"180px"} height={"180px"} borderRadius={"100%"} bgcolor={"rgba(92, 131, 191, 0.1)"}></Box>
                  <img src={MyAvatar} alt="Avatar" style={{position:"relative",top:"30px", left: isMediumScreen ? "0px" : "-33px", zIndex:"3"}} />
                </Grid>
                <Grid display={'flex'} flexDirection={'column'} gap={"30px"}>
                  <Grid display={'flex'} alignItems={'center'}>
                  <Button sx={{color:"secondary.main"}} onClick={() => setView("profile")}>
                    <Box   
                      sx={{
                        bgcolor: "primary.darkerBlue",
                        width: "42px",
                        height: "42px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center", 
                        alignItems: "center",
                      }}
                    >
                      <SvgIcon component={TherapistsIcon} width="20px" height="20px"/>
                    </Box>
                    <Typography variant={isSmallScreen ? 'sb16Bolder' : 'sb24Bolder'}  sx={{color: "primary.darkerBlue"}}>My Profile</Typography>
                  </Button>
                  <div className="vertical-line" style={{background:"#E3DBD5", height:"2rem"}} />
                  <Button onClick={() => setView("appointments")}>
                    <SvgIcon component={AppointmentsIcon} width="32px" height="32px"/>
                    <Typography variant={isSmallScreen ? 'sb16Bold' : 'sb24Bold'} sx={{color: "primary.darkerBlue"}}>My Appointments</Typography>
                  </Button>
                  </Grid>
              </Grid>
              </Grid>
            )}
          </Box>
            {/* add onboarding button */}
              <Grid display={'flex'} alignItems={'center'} gap={"10px"}>
                <img src={therapistInfo?.img_url} alt={therapistInfo?.name} className="therapist-image me-3" />
                <Grid>
                {editNameMode ? (
                      <form onSubmit={handleNameSubmit}>
                      <input
                          type="text"
                          value={editedName}
                          className="form-control profile_text mt-2" 
                          onChange={handleNameChange}
                      />
                      <div className="d-flex gap-2 mt-2">
                          <button type="submit" className="btn btn-primary">Save</button>
                          <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                      </div>
                      </form>
                  ) : (
                    <Typography variant='h42Bold' color={"#4C6EA2"} sx={{display:"flex", alignItems:"center", gap:"10px"}}>
                        {therapistInfo?.name || "Name Surname"}  
                          <span>
                          <button onClick={() => setEditNameMode(true)} className="back_button pe-3 ps-1">
                              <h4>
                                  <FontAwesomeIcon color={"#4C6EA2"} size='lg' icon="fa-regular fa-pen-to-square" className="mx-1"/>
                              </h4>
                          </button>
                          </span>
                      </Typography>
                  )}
                  {editTitleMode ? (
                      <form onSubmit={handleTitleSubmit}>
                      <input
                          type="text"
                          value={editedTitle}
                          className="form-control profile_text mt-2" 
                          onChange={handleTitleChange}
                      />
                      <div className="d-flex gap-2 mt-2">
                          <button type="submit" className="btn btn-primary">Save</button>
                          <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                      </div>
                      </form>
                  ) : (
                      <Typography variant='h32Bold' color={"#4C6EA2"} sx={{display:"flex", alignItems:"center", gap:"10px"}}>
                          {therapistInfo?.therapist_title || "Title"}  
                          <button onClick={() => setEditTitleMode(true)} className="back_button pe-3 ps-1">
                              <FontAwesomeIcon color={"#4C6EA2"} size='sm' icon="fa-regular fa-pen-to-square" className="mx-1"/>
                          </button>
                      </Typography>
                  )}
                  <div>
                      {editBioMode ? (
                      <form onSubmit={handleBioSubmit} style={{width: "500px"}}>
                      <textarea
                          value={editedBio}
                          onChange={handleBioChange}
                          className="form-control profile_text p-2 mt-2"
                          style={{width: "500px"}}
                      />
                      <div className="mt-2">Word Count: {bioWordCount}/20</div>
                      <div className="d-flex gap-2 mt-2">
                          <button type="submit" className="btn btn-primary">Save</button>
                          <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                      </div>
                      </form>
                  ) : (   
                      <p className="profile_text">
                          {therapistInfo?.bio || "Short bio (max 20 words)"}  
                          <button onClick={() => setEditBioMode(true)} className="back_button pe-3 ps-1">
                              <FontAwesomeIcon color={"#4C6EA2"} icon="fa-regular fa-pen-to-square" className="mx-1"/>
                          </button>
                          
                      </p>
                  )}
                  </div>
                </Grid>
              </Grid>
          </Grid>
          <div className="d-flex justify-content-center my-3">
            {onboardingComplete ? (
              <Button variant='primary' onClick={() => window.location.href = 'https://dashboard.stripe.com'}>
                <Typography variant='sb16Bolder'>Manage Payments</Typography>
                </Button>
            ) : (
              <button onClick={handleOnboarding} className="btn blue_btn">Add Bank Account</button>
            )}
            {/* Render toast message if needed */}
            {showToast && <div className={`toast ${toastType}`}>{toastMessage}</div>}
            {/* Floating Warning for incomplete onboarding */}
            {!onboardingComplete && (
              <div className="floating-warning mt-5 mb-3">
                <span role="img" aria-label="alert">⚠️</span>Please complete your bank details and add your available timeslots to be visible to the users on the platform.
              </div>
            )}
          </div>
          <Grid container justifyContent={'center'}>
          <Grid xs={12} sm={10} lg={7} padding={"24px 32px 32px 32px"} gap={"32px"} bgcolor={"white"} borderRadius={"10px"} border={"1px solid #E3DBD5"} boxShadow={"0px 5px 10px #E9E2DD"}>
              <Grid display={'flex'} flexDirection={'column'} gap={"32px"}>
              <Grid display={'flex'} flexDirection={'column'}>
                  {editAboutMode ? (
                  <>
                  <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>About</Typography>
                  <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                  <div className="d-flex p-2">
                      <form onSubmit={handleAboutSubmit} style={{ width: "100%" }}>
                          <textarea
                              value={editedAbout}
                              onChange={handleAboutChange}
                              className="p-2 form-control"
                              rows="9"
                              style={{ width: "100%" }} 
                          />
                          <div className="mt-2">Word Count: {aboutWordCount}/200</div>
                          <div className="d-flex justify-content-end gap-2">
                              <button type="submit" className="btn btn-primary">Save</button>
                              <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                          </div>
                      </form>
                  </div>
                  </>
                  ) : (
                  <>
                  <div className="d-flex justify-content-between">
                    <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>About</Typography>
                      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{backgroundColor:"white", borderRadius:"100%", boxShadow:"0px 5px 10px rgba(0, 0, 0, 0.14)", width:"40px", height:"40px"}}>
                          <button onClick={() => setEditAboutMode(true)} className="back_button">
                              <FontAwesomeIcon color='#4C6EA2' icon="fa-regular fa-pen-to-square" size='lg'/>
                          </button>
                      </Box>
                  </div>
                  <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                  <div className="d-flex p-2">
                    <Typography variant='sb16SemiBold' lineHeight={"26px"}>{therapistInfo?.long_bio}</Typography>
                  </div>
                  </>
                  )}
              </Grid>
              <Grid display={'flex'} flexDirection={'column'}>
                  {editApproachMode ? (
                  <>
                  <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>Approach</Typography>
                  <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                  <div className="d-flex p-2">
                      <form onSubmit={handleApproachSubmit} style={{ width: "100%" }}>
                          <textarea
                              value={editedApproach}
                              onChange={handleApproachChange}
                              className="p-2 form-control"
                              rows="9"
                              style={{ width: "100%" }} 
                          />
                          <div className="mt-2">Word Count: {approachWordCount}/200</div>
                          <div className="d-flex justify-content-end gap-2">
                              <button type="submit" className="btn btn-primary">Save</button>
                              <button className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                          </div>
                      </form>
                  </div>
                  </>
                  ) : (
                  <>
                      <div className="d-flex justify-content-between">
                          <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>Approach</Typography>
                          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{backgroundColor:"white", borderRadius:"100%", boxShadow:"0px 5px 10px rgba(0, 0, 0, 0.14)", width:"40px", height:"40px"}}>
                            <button onClick={() => setEditAreaMode(true)} className="back_button">
                                <FontAwesomeIcon color='#4C6EA2' icon="fa-regular fa-pen-to-square" size='lg'/>
                            </button>
                          </Box>
                      </div>
                      <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                      <div className="d-flex p-2">
                        <Typography variant='sb16SemiBold' lineHeight={"26px"}>{therapistInfo?.therapist_approach}</Typography>
                      </div>
                  </>
                  )}
              </Grid>
              <Grid display={'flex'} flexDirection={'column'}>
                  {editEducationMode ? (
                  <>
                      <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>Education</Typography>
                      <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                      <form onSubmit={handleEducationSubmit}>
                          {editedEducations?.map((education, index) => (
                          <div key={index} className="mb-2 d-flex">
                              <input
                              type="text"
                              value={education}
                              onChange={(e) => handleEducationChange(index, e.target.value)}
                              className="form-control"
                              />
                              <button className="btn" onClick={() => handleDeleteEducation(index)}>                          
                                  <FontAwesomeIcon icon="fa-regular fa-trash-can" style={{ color: '#005eb1' }} size="lg" />
                              </button>
                          </div>
                          ))}
                          <div className="d-flex justify-content-between mt-4">
                              <button type="button" className="back_button" onClick={handleAddEducation}>
                              <FontAwesomeIcon icon={faPlus} className="me-2" />Add New</button>
                              <div className="d-flex gap-2">
                                  <button type="submit" className="btn btn-primary">Save</button>
                                  <button onClick={handleCancel} className="btn btn-secondary">Cancel</button>
                              </div>
                          </div>
                      </form>
                  </>
                  ) : (
                  <>
                      <div className="d-flex justify-content-between">
                        <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>Education</Typography>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{backgroundColor:"white", borderRadius:"100%", boxShadow:"0px 5px 10px rgba(0, 0, 0, 0.14)", width:"40px", height:"40px"}}>
                          <button onClick={() => setEditExperienceMode(true)} className="back_button">
                              <FontAwesomeIcon color='#4C6EA2' icon="fa-regular fa-pen-to-square" size='lg'/>
                          </button>
                        </Box>
                      </div>
                      <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                      <Grid display={'flex'} flexDirection={'column'} gap={"10px"} p={1}>
                        {therapistInfo && therapistInfo.educations?.map((education, index) => (
                            <Grid key={index}>
                              <Typography variant='sb16SemiBold'>{education}</Typography>
                            </Grid>
                        ))}
                      </Grid>
                  </>
                  )}
                  </Grid>
                  <Grid display={'flex'} flexDirection={'column'}>
                {editExperienceMode ? (
                  <>
                      <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>Experiences</Typography>
                      <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                      <form onSubmit={handleExperienceSubmit}>
                       {editedExperiences?.map((experience, index) => (
                          <div key={index} className="mb-2 d-flex">
                              <input
                              type="text"
                              value={experience}
                              onChange={(e) => handleExperienceChange(index, e.target.value)}
                              className="form-control"
                              />
                              <button className="btn" onClick={() => handleDeleteExperience(index)}>                          
                                  <FontAwesomeIcon icon="fa-regular fa-trash-can" style={{ color: '#005eb1' }} size="lg" />
                              </button>
                          </div>
                          ))}
                          <div className="d-flex justify-content-between mt-4">
                              <button type="button" className="back_button" onClick={handleAddExperience}>
                              <FontAwesomeIcon icon={faPlus} className="me-2" />Add New</button>
                              <div className="d-flex gap-2">
                                  <button type="submit" className="btn btn-primary">Save</button>
                                  <button onClick={handleCancel} className="btn btn-secondary">Cancel</button>
                              </div>
                          </div>
                      </form>
                  </>
                  ) : (
                  <Grid>
                      <div className="d-flex justify-content-between">
                        <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>Experiences</Typography>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{backgroundColor:"white", borderRadius:"100%", boxShadow:"0px 5px 10px rgba(0, 0, 0, 0.14)", width:"40px", height:"40px"}}>
                          <button onClick={() => setEditExperienceMode(true)} className="back_button">
                              <FontAwesomeIcon color='#4C6EA2' icon="fa-regular fa-pen-to-square" size='lg'/>
                          </button>
                        </Box>
                      </div>
                      <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                      <Grid display={'flex'} flexDirection={'column'} gap={"10px"} p={1}>
                        {therapistInfo && therapistInfo.experiences?.map((experience, index) => (
                            <Grid key={index}>
                              <Typography variant='sb16SemiBold'>{experience}</Typography>
                            </Grid>
                        ))}
                      </Grid>
                  </Grid>
                  )}
                  </Grid>
                  <Grid display={'flex'} flexDirection={"column"}>
                  {editAreaMode ? (
                  <>
                      <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>Interests</Typography>
                      <div className="horizontal-line" style={{background: "#E5E5E5", marginBottom: "15px"}} />
                      <form onSubmit={handleAreaSubmit}>
                          {editedAreas?.map((area, index) => (
                          <div key={index} className="mb-2 d-flex">
                              <input
                              type="text"
                              value={area}
                              onChange={(e) => handleAreaChange(index, e.target.value)}
                              className="form-control"
                              />
                              <button className="btn" onClick={() => handleDeleteArea(index)}>                          
                                  <FontAwesomeIcon icon="fa-regular fa-trash-can" style={{ color: '#005eb1' }} size="lg" />
                              </button>
                          </div>
                          ))}
                          <div className="d-flex justify-content-between mt-4">
                              <button type="button" className="back_button" onClick={handleAddArea}>
                              <FontAwesomeIcon icon={faPlus} className="me-2" />Add New</button>
                              <div className="d-flex gap-2">
                                  <button type="submit" className="btn btn-primary">Save</button>
                                  <button onClick={handleCancel} className="btn btn-secondary">Cancel</button>
                              </div>
                          </div>
                      </form>
                  </>
                  ) : (
                  <>
                      <div className="d-flex justify-content-between">
                        <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>Interests</Typography>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{backgroundColor:"white", borderRadius:"100%", boxShadow:"0px 5px 10px rgba(0, 0, 0, 0.14)", width:"40px", height:"40px"}}>
                          <button onClick={() => setEditAreaMode(true)} className="back_button">
                              <FontAwesomeIcon color='#4C6EA2' icon="fa-regular fa-pen-to-square" size='lg'/>
                          </button>
                        </Box>
                      </div>
                      <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                      <Grid container justifyContent={{xs:"center", sm:"start"}} gap={"10px"}  p={1}>
                      {therapistInfo?.areas?.map((area, index) => (
                          <Grid key={index} sx={{width:"fit-content", borderRadius:"40px", border:"2px solid #4C6EA2", padding:"12px", background:"rgba(92, 131, 191, 0.1)", display:"flex", alignItems:"center"}}>
                          <Typography color={"#4C6EA2"} variant='sb16Bold'>{area}</Typography>
                          </Grid>
                      ))}
                      </Grid>
                  </>
                  )}
                  </Grid>
                  <Grid display={'flex'} flexDirection={'column'}>
                  {editLanguageMode ? (
                      <>
                      <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>Spoken Languages</Typography>
                      <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                      <form onSubmit={handleLanguageSubmit}>
                          {editedLanguages?.map((language, index) => (
                          <div key={index} className="mb-2 d-flex">
                              <input
                              type="text"
                              value={language}
                              onChange={(e) => handleLanguageChange(index, e.target.value)}
                              className="form-control"
                              />
                              <button className="btn" onClick={() => handleDeleteLanguage(index)}>                          
                                  <FontAwesomeIcon icon="fa-regular fa-trash-can" style={{ color: '#005eb1' }} size="lg" />
                              </button>
                          </div>
                          ))}
                          <div className="d-flex justify-content-between mt-4">
                              <button className="back_button" onClick={handleAddLanguage}>
                              <FontAwesomeIcon icon={faPlus} className="me-2" />Add New</button>
                              <div className="d-flex gap-2">
                                  <button type="submit" className="btn btn-primary">Save</button>
                                  <button onClick={handleCancel} className="btn btn-secondary">Cancel</button>
                              </div>
                          </div>
                      </form>
                  </>
                  ) : (
                  <>
                      <div className="d-flex justify-content-between">
                        <Typography variant='sb20SemiBold' pl={1} color={"#4C6EA2"}>Spoken Languages</Typography>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{backgroundColor:"white", borderRadius:"100%", boxShadow:"0px 5px 10px rgba(0, 0, 0, 0.14)", width:"40px", height:"40px"}}>
                          <button onClick={() => setEditLanguageMode(true)} className="back_button">
                              <FontAwesomeIcon color='#4C6EA2' icon="fa-regular fa-pen-to-square" size='lg'/>
                          </button>
                        </Box>
                      </div>
                      <div className="horizontal-line" style={{background: "#E5E5E5"}} />
                      <Grid container justifyContent={{xs:"center", sm:"start"}} gap={"10px"}  p={1}>
                      {therapistInfo && therapistInfo.languages?.map((language, index) => (
                          <Grid key={index} sx={{width:"fit-content", borderRadius:"40px", border:"2px solid #4C6EA2", padding:"12px", background:"rgba(92, 131, 191, 0.1)", display:"flex", alignItems:"center"}}>
                            <Typography color={"#4C6EA2"} variant='sb16Bold'>{language}</Typography>
                          </Grid>
                      ))}
                      </Grid>
                  </>
                  )}
                  </Grid>
              </Grid>
              <Grid className="d-flex flex-column">
              </Grid>
          </Grid>
          </Grid>
          </Grid>
        )
        
      )}   
    </div>
  );
};

export default EditTherapistProfile;
