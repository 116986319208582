import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import {
  Container,
  Alert,
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Fade
} from '@mui/material';
import { URL } from '../Helpers';

const SlackCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading: userLoading } = useUser();
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('initial'); // 'initial', 'processing', 'success', 'error'
  const [debugInfo, setDebugInfo] = useState({
    code: null,
    state: null,
    hasUser: false,
    stage: 'initial'
  });

  useEffect(() => {
    const handleSlackCallback = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        const state = params.get('state');

        setDebugInfo(prev => ({
          ...prev,
          code: !!code,
          state: !!state,
          hasUser: !!user,
          stage: 'checking-params'
        }));

        console.log('SlackCallback: Starting callback handling', {
          hasCode: !!code,
          hasState: !!state,
          hasUser: !!user
        });

        if (!code) {
          throw new Error('No authorization code provided by Slack');
        }

        if (!state) {
          throw new Error('No state parameter provided');
        }

        if (!user) {
          // Store code and state temporarily
          localStorage.setItem('slackCallbackCode', code);
          localStorage.setItem('slackCallbackState', state);
          setDebugInfo(prev => ({ ...prev, stage: 'redirecting-to-auth' }));
          // Redirect to auth page
          navigate('/auth', { state: { from: '/slack/callback' } });
          return;
        }

        setStatus('processing');
        setDebugInfo(prev => ({ ...prev, stage: 'making-request' }));

        // Exchange code for token
        console.log('Making request to:', `${URL}/slack/oauth/callback`);
        const response = await fetch(`${URL}/slack/oauth/callback`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${await user.getIdToken()}`,
            'Content-Type': 'application/json'
          }
        });

        setDebugInfo(prev => ({ ...prev, stage: 'processing-response' }));

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || 'Failed to authenticate with Slack');
        }

        const data = await response.json();
        
        setDebugInfo(prev => ({ ...prev, stage: 'success' }));
        setStatus('success');
        
        // Redirect after a short delay to show success message
        setTimeout(() => {
          const redirectUrl = localStorage.getItem('slackAuthRedirect') || '/user-profile';
          localStorage.removeItem('slackAuthRedirect');
          navigate(redirectUrl, {
            state: { 
              slackSuccess: true,
              message: 'Slack integration successful! You can now use /poka commands.' 
            }
          });
        }, 2000);

      } catch (err) {
        console.error('Slack auth error:', err);
        setError(err.message);
        setStatus('error');
        setDebugInfo(prev => ({ ...prev, stage: 'error', errorMessage: err.message }));
      }
    };

    const initializeCallback = () => {
      // Check for stored callback data
      const storedCode = localStorage.getItem('slackCallbackCode');
      const storedState = localStorage.getItem('slackCallbackState');

      if (storedCode && storedState) {
        setDebugInfo(prev => ({ ...prev, stage: 'found-stored-params' }));
        // Clear stored data
        localStorage.removeItem('slackCallbackCode');
        localStorage.removeItem('slackCallbackState');
        // Reconstruct URL and navigate
        const params = new URLSearchParams();
        params.set('code', storedCode);
        params.set('state', storedState);
        navigate(`/slack/callback?${params.toString()}`);
      } else if (location.search) {
        setDebugInfo(prev => ({ ...prev, stage: 'processing-callback' }));
        handleSlackCallback();
      } else {
        setDebugInfo(prev => ({ ...prev, stage: 'no-params' }));
        setError('No callback parameters found');
        setStatus('error');
      }
    };

    if (!userLoading) {
      initializeCallback();
    }
  }, [user, userLoading, location, navigate]);

  const renderContent = () => {
    switch (status) {
      case 'initial':
      case 'processing':
        return (
          <>
            <CircularProgress sx={{ color: 'white', mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              Connecting your Slack account...
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, textAlign: 'center' }}>
              Stage: {debugInfo.stage}
            </Typography>
          </>
        );

      case 'error':
        return (
          <>
            <Typography variant="h5" component="h1" gutterBottom textAlign="center">
              Connection Failed
            </Typography>
            <Alert 
              severity="error" 
              sx={{
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                color: 'white',
                border: '1px solid rgba(255, 0, 0, 0.3)',
                mb: 3,
                width: '100%'
              }}
            >
              {error || 'Failed to connect with Slack'}
            </Alert>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                sx={{ 
                  backgroundColor: 'white',
                  color: '#5C83BF',
                  '&:hover': {
                    backgroundColor: '#f5f5f5'
                  }
                }}
                onClick={() => navigate('/user-profile')}
              >
                Return to Profile
              </Button>
              <Button
                variant="outlined"
                sx={{ 
                  borderColor: 'white',
                  color: 'white',
                  '&:hover': {
                    borderColor: '#f5f5f5',
                    backgroundColor: 'rgba(255,255,255,0.1)'
                  }
                }}
                onClick={() => {
                  localStorage.removeItem('slackCallbackCode');
                  localStorage.removeItem('slackCallbackState');
                  window.location.reload();
                }}
              >
                Try Again
              </Button>
            </Box>
          </>
        );

      case 'success':
        return (
          <>
            <Typography variant="h5" component="h1" gutterBottom textAlign="center">
              Success! 🎉
            </Typography>
            <Alert 
              severity="success" 
              sx={{
                backgroundColor: 'rgba(0, 255, 124, 0.15)',
                color: 'white',
                border: '1px solid rgba(0, 255, 124, 0.5)',
                mb: 3,
                width: '100%'
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Slack account connected successfully!
              </Typography>
              <Typography variant="body2">
                You can now use /poka commands in Slack.
              </Typography>
            </Alert>
            <Typography variant="body1" textAlign="center" sx={{ opacity: 0.9, mb: 2 }}>
              Redirecting you back...
            </Typography>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Box 
      sx={{ 
        minHeight: '100vh',
        backgroundColor: '#FFF7F1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        py: 4
      }}
    >
      <Fade in timeout={800}>
        <Paper 
          sx={{ 
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#5C83BF',
            color: 'white',
            borderRadius: 2,
            width: '100%',
            maxWidth: '500px',
            mx: 2
          }}
        >
          {renderContent()}
          
          {/* Debug information in development */}
          {process.env.NODE_ENV === 'development' && (
            <Box sx={{ mt: 3, p: 2, backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 1 }}>
              <Typography variant="caption" component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
                Debug Info:
                {JSON.stringify(debugInfo, null, 2)}
              </Typography>
            </Box>
          )}
        </Paper>
      </Fade>
    </Box>
  );
};

export default SlackCallback;