import { URL } from "../../Helpers";

export const formatTime = (seconds) => {
  if (!seconds && seconds !== 0) return "00:00";
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
};

export const tierLimits = {
  free: {
    weeklyLimit: 1,
    dailyLimit: true,
    SESSION_TIMER: 180 // 3 minutes
  },
  premium: {
    weeklyLimit: 12,
    dailyLimit: false,
    durationToStart: 180,
    dailyDurationLimit: 600,
    SESSION_TIMER: 600 // 10 minutes
  }
};

export const startVisualizer = (canvas, audiofile = null, audioStream = null) => {
  if (!canvas) return;

  const ctx = canvas.getContext("2d");
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const audioSource = audiofile
    ? audioContext.createMediaElementSource(audiofile)
    : audioContext.createMediaStreamSource(audioStream);
  const analyser = audioContext.createAnalyser();

  audioSource.connect(analyser);
  if (audiofile) {
    audiofile.play();
    analyser.connect(audioContext.destination);
  }

  analyser.fftSize = 512;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  const circleCount = 3;
  const baseRadius = parseInt(canvas.height / 4.5);
  let lastRadii = new Array(circleCount).fill(baseRadius);
  const colorBase = audiofile ? { r: 59, g: 130, b: 246 } : { r: 255, g: 161, b: 84 };

  function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end;
  }

  function animate() {
    requestAnimationFrame(animate);
    analyser.getByteFrequencyData(dataArray);

    let sum = dataArray.reduce((a, b) => a + b, 0);
    let average = sum / bufferLength;
    let normAverage = average / 128;

    canvas.style.visibility = average === 0 ? "hidden" : "visible";

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const scalingFactors = [1, 1.5, 4];
    const opacityLevels = [0.8, 0.6, 0.4];
    const borderThicknesses = [5, 4, 3];

    for (let i = 0; i < circleCount; i++) {
      const scaledAverage = Math.pow(normAverage, 2) * scalingFactors[i];
      const maxRadius = Math.min(canvas.width, canvas.height) / 2;
      const targetRadius = Math.min(baseRadius + scaledAverage * baseRadius * 2, maxRadius);

      lastRadii[i] = lerp(lastRadii[i], targetRadius, 0.3);

      const x = canvas.width / 2;
      const y = canvas.height / 2;

      ctx.lineWidth = borderThicknesses[i];
      ctx.beginPath();
      ctx.arc(x, y, lastRadii[i], 0, Math.PI * 2, false);
      ctx.strokeStyle = `rgba(76, 110, 162, ${opacityLevels[i]})`;
      ctx.fillStyle = `rgba(${colorBase.r}, ${colorBase.g}, ${colorBase.b}, 0)`;
      ctx.fill();
      ctx.stroke();
    }
  }

  animate();
  return () => {
    audioContext.close();
  };
};

export const createSessionDir = async (sessionDate, user,topic) => {
  try {
    const response = await fetch(`${URL}/session_dir/${sessionDate}`, {
      headers: {
        'Authorization': `Bearer ${user.accessToken}`,
        'content-type': 'application/json'
      },
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({topic:topic})
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating session directory:', error);
    return null;
  }
};

export const uploadData = async (data, url, accessToken) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: data
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`Network response was not ok: ${errorData.message}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error uploading data:', error);
    throw error;
  }
};

export const handleWarning = (message, setShowWarning, setWarningMessage) => {
  setShowWarning(true);
  setWarningMessage(message);

  setTimeout(() => {
    setShowWarning(false);
    setWarningMessage(null);
  }, 1500);
};

export const uploadAudioBlob = async (blob, sessionDate, tempDirId, accessToken) => {
  const formData = new FormData();
  formData.append('audio', blob, 'audio.wav');

  try {
    const response = await fetch(
      `${URL}/upload_audio/${sessionDate}/${tempDirId}`,
      {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        },
        body: formData
      }
    );

    if (!response.ok) {
      throw new Error('Failed to upload audio');
    }

    return await response.json();
  } catch (error) {
    console.error('Error uploading audio:', error);
    throw error;
  }
};

// Simple utility function for text-to-speech
export const speak = (text, voice = null, rate = 1, pitch = 1) => {
  const synth = window.speechSynthesis;
  const utterance = new SpeechSynthesisUtterance(text);

  // Optional: Set voice if provided
  if (voice) {
    utterance.voice = voice;
  }

  utterance.rate = rate;  // Speed of speech: 0.1 to 10
  utterance.pitch = pitch; // Pitch: 0 to 2

  synth.speak(utterance);

  return utterance; // Return for controlling playback
};

// Get available voices
export const getVoices = () => {
  return new Promise((resolve) => {
    let voices = speechSynthesis.getVoices();
    if (voices.length) {
      resolve(voices);
      return;
    }

    speechSynthesis.onvoiceschanged = () => {
      voices = speechSynthesis.getVoices();
      resolve(voices);
    };
  });
};
