import React, { useState, createContext, useContext, useEffect, useCallback } from 'react';
import { onAuthStateChanged, getAdditionalUserInfo, getIdToken } from "firebase/auth";
import { auth } from '../Firebase';
import LoadingSpinner from '../pages/shared/LoadingSpinner';
import LoadingScreen from '../pages/shared/WellnessLoader';
import SessionResponseSpinner from '../pages/shared/SessionResponseSpinner';
import { useNavigate } from 'react-router-dom';
import { fetchUserRole, createNewUserDocument } from '../Helpers';
import "../pages/style.scss";
import { URL } from '../Helpers';
import { sendMessageToExtension } from '../ExtensionUtils';

const UserContext = createContext();

// In UserProvider
export const UserProvider = ({ children }) => {
  const initialRole = localStorage.getItem('role');
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(initialRole); // Use role from local storage
  const [tier, setTier] = useState(null)
  const [name, setName] = useState(null);
  const [premium_status, setPremium_status] = useState(null)
  const [stripe_customer_id, setStripe_customer_id] = useState(null);
  const [stripe_subscription_id, setStripe_subscription_id] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const navigate = useNavigate();
  // Function to refresh user claims
  const refreshUserClaims = useCallback(async (user) => {
    try {
      const token = await user.getIdTokenResult(true);//force auth token refresh
      setRole(token.claims.role); // Update the role based on the new claims
      setTier(token.claims.tier || "free")
      setName(token.claims.name || null)
      setPremium_status(token.claims.premium_status || null)
      setStripe_customer_id(token.claims.stripe_customer_id || null)
      setStripe_subscription_id(token.claims.stripe_subscription_id || null)
    } catch (error) {
      console.error('Failed to refresh user claims:', error);
    }
  }, []);

  //function to set user custom claims
  const setUserClaims = useCallback(async (user) => {
    try {
      const token = await user.getIdTokenResult();  // Force token refresh
      setRole(token.claims.role || "user");
      setTier(token.claims.tier || "free");
      setName(token.claims.name || null);
      setPremium_status(token.claims.premium_status || null);
      setStripe_customer_id(token.claims.stripe_customer_id || null);
      setStripe_subscription_id(token.claims.stripe_subscription_id || null);
    } catch (error) {
      console.error('Failed to set user claims:', error);
    }
  }, []);

  const clearClaims = useCallback(async () => {
    setRole(null);
    setTier(null)
    setName(null)
    setPremium_status(null)
    setStripe_customer_id(null)
    setStripe_subscription_id(null)
  }, []);


  const pingBackend = useCallback(async (user) => {
    try {
      const idToken = await getIdToken(user);  // Force token refresh
      const response = await fetch(`${URL}/ping`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        const data = await response.json();
        console.log('Ping response (JSON):', data);

      } else {
        const textData = await response.text();
        console.log('Ping response (text):', textData);
        // Handle non-JSON response here
        if (textData === "pong") {
          console.log("Received 'pong' response from server");
          // You might want to do something here to confirm the connection
        }
      }
    } catch (error) {
      console.error('Error pinging backend:', error);
    }
  }, []);

  //to get custom token to send to extension for authentication
  const getCustomToken = useCallback(async (user) => {
    const idToken = await getIdToken(user);  // Force token refresh
    const response = await fetch(`${URL}/get_custom_token`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
    });
    const data = await response.json();
    return data.token;
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      try {
        if (!user) {
          setUser(null);
          clearClaims()
          // on signout send message to extension
          sendMessageToExtension({
            message: "auth_state_changed",
            token: null
          }).catch(e=>console.error("Error sending auth to extension:", e))
          // navigate('/');//moved to protected route to handle that
        } else {
          setUser(user);
          await setUserClaims(user);
          // await pingBackend(user);
          setUserClaims(user)
          //on signin get custom token to send ot extension
          const token = await getCustomToken(user)
          sendMessageToExtension({
            message: "auth_state_changed",
            token: token
          }).catch((error) => {
            console.error('Error sending auth to extension:', error);
          })
        }
      } catch (error) {
        console.error('Failed to handle auth state change:', error);
      }
      setIsLoading(false);
    });

    return unsubscribe;
  }, [setUserClaims, clearClaims, pingBackend]);

  //wait till onauthstatechanged finishes processing
  if (isLoading) {
    return (

        <LoadingScreen />
    );
  }




  return (
    <UserContext.Provider value={{ user, setUser, role, setRole, tier, name, refreshUserClaims, premium_status, stripe_customer_id, stripe_subscription_id }}>
      {children}
    </UserContext.Provider>
  );

};

export default UserContext;

export const useUser = () => {
  return useContext(UserContext);
};
