// components/Analytics.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { firebase } from '../Firebase'; // Update the import path based on your Firebase.js location

let analyticsInstance = null;

export function Analytics() {
    const location = useLocation();

    useEffect(() => {
        const initAnalytics = () => {
            if (!analyticsInstance && window.location.hostname !== 'localhost') {
                try {
                    analyticsInstance = getAnalytics(firebase);
                } catch (error) {
                    console.error('Failed to initialize Firebase Analytics:', error);
                    return;
                }
            }

            if (analyticsInstance) {
                logEvent(analyticsInstance, 'page_view', {
                    page_path: location.pathname,
                    page_search: location.search,
                    page_title: document.title,
                });
            }
        };

        initAnalytics();
    }, [location]);

    return null;
}

// Custom hook for logging events
export function useAnalytics() {
    const logCustomEvent = (eventName, eventParams = {}) => {
        if (!analyticsInstance && window.location.hostname !== 'localhost') {
            try {
                analyticsInstance = getAnalytics(firebase);
            } catch (error) {
                console.error('Failed to initialize Firebase Analytics:', error);
                return;
            }
        }

        if (analyticsInstance) {
            logEvent(analyticsInstance, eventName, eventParams);
        }
    };

    return { logCustomEvent };
}

// Example usage of custom events:
/*
import { useAnalytics } from './components/Analytics';

function MyComponent() {
  const { logCustomEvent } = useAnalytics();

  const handleButtonClick = () => {
    logCustomEvent('button_click', {
      button_name: 'submit',
      page_section: 'header'
    });
  };

  return <button onClick={handleButtonClick}>Click me</button>;
}
*/