import React, { useRef, useState,useCallback } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, SvgIcon, Box, Button } from "@mui/material";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as videoIcon } from '../../data/videoIcon.svg';
import { ReactComponent as stopCircle } from '../../data/stop_circle.svg';
import { BarLoader } from 'react-spinners';
import Webcam from 'react-webcam';

// Language voice mapping
const LANGUAGE_VOICES = {
  'en-GB': { name: 'Google UK English Male', langCode: 'en-GB', gender: 'male' },
};

export const RecordingUI = ({
  countdown,
  currentQuestion,
  buttonDisabled,
  showQuestions,
  toggleQuestions,
  canvasParentRef,
  webcamVisible,
  showAvatar,
  videoAccess,
  camRef,
  micCanvasRef,
  audioCanvasRef,
  audioRef,
  audioEndingRef,
  WarningMessage,
  overviewData,
  toggleWebcam,
  setShowAvatar,
  stopRecording,
  responseLoading,
  handleScreenshots,
  formatTime,
  onMediaError,
  language = 'en-GB' // default to British English
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const utteranceRef = useRef(null);
  const isFirstMount = useRef(true);
  const previousQuestionRef = useRef('');

  // Function to get the appropriate voice for the selected language
  const getVoiceForLanguage = (langCode) => {
    const voices = window.speechSynthesis.getVoices();
    console.log(voices);
    // Find the voice that matches the full name of the language Google UK English Male

    return voices.find((voice) => voice.name === LANGUAGE_VOICES[langCode].name);

  };

  // Function to initialize voices
  const initializeVoices = () => {
    return new Promise((resolve) => {
      const voices = window.speechSynthesis.getVoices();
      if (voices.length > 0) {
        resolve(voices);
      } else {
        window.speechSynthesis.onvoiceschanged = () => {
          resolve(window.speechSynthesis.getVoices());
        };
      }
    });
  };

  

// Enhanced speech function
const speakQuestion = useCallback(async (text) => {
  if (!text) return;
  
  // Always cancel any ongoing speech
  window.speechSynthesis.cancel();
  if (utteranceRef.current) {
    setIsPlaying(false);
    utteranceRef.current = null;
  }

  try {
    const utterance = new SpeechSynthesisUtterance(text);
    utteranceRef.current = utterance;

    // Get voices and wait if needed
    const voices = await new Promise((resolve) => {
      let voices = window.speechSynthesis.getVoices();
      if (voices.length) {
        resolve(voices);
      } else {
        window.speechSynthesis.onvoiceschanged = () => {
          resolve(window.speechSynthesis.getVoices());
        };
      }
    });

    const voice = voices.find(v => v.name === LANGUAGE_VOICES[language].name);
    if (voice) {
      utterance.voice = voice;
      utterance.lang = LANGUAGE_VOICES[language].langCode;
    }

    utterance.rate = 1;
    utterance.pitch = 1;
    utterance.volume = 1;

    utterance.onstart = () => setIsPlaying(true);
    utterance.onend = () => {
      setIsPlaying(false);
      utteranceRef.current = null;
    };
    utterance.onerror = (event) => {
      console.error('Speech synthesis error:', event);
      setIsPlaying(false);
      utteranceRef.current = null;
    };

    window.speechSynthesis.speak(utterance);
  } catch (error) {
    console.error('Error speaking question:', error);
  }
}, [language]);


  // Handle manual play/pause
  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      window.speechSynthesis.cancel();
      setIsPlaying(false);
    } else if (currentQuestion) {
      speakQuestion(currentQuestion);
    }
  }, [isPlaying, currentQuestion, speakQuestion]);



  // Handle initial text speech
  React.useEffect(() => {
    if (isFirstMount.current && currentQuestion) {
      speakQuestion(currentQuestion);
      isFirstMount.current = false;
      previousQuestionRef.current = currentQuestion;
    }
  }, [currentQuestion, speakQuestion]);

    // Handle question changes
    React.useEffect(() => {
      if (!isFirstMount.current && currentQuestion && currentQuestion !== previousQuestionRef.current) {
        speakQuestion(currentQuestion);
        previousQuestionRef.current = currentQuestion;
      }
    }, [currentQuestion, speakQuestion]);
  

 // Enhanced toggle questions handler
 const handleToggleQuestions = useCallback(() => {
  toggleQuestions();
}, [toggleQuestions]);
  

  // Cleanup effect
  React.useEffect(() => {
    return () => {
      window.speechSynthesis.cancel();
      if (utteranceRef.current) {
        setIsPlaying(false);
        utteranceRef.current = null;
      }
    };
  }, []);



  return (
    <Grid gap={5} marginBottom={"100px"} container display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
      <Grid md={7.5} lg={6.7} xl={5} xs={10} alignItems={"center"} border={"solid 1px #E3DBD5"} borderRadius={"10px"} justifyContent={"center"} sx={{ background: "#FFFFFF" }}>
        {/* Header */}
        <Grid md={7.5} lg={6.7} xl={5} xs={10} padding={"16px 24px 16px 24px"} borderRadius={"10px 10px 0px 0px"} sx={{ background: "#5C83BF" }} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography color={"white"} variant="sb24Bold">Session in progress</Typography>
          <Grid sx={{ '@media (max-width: 450px)': { display: "flex", flexDirection: "column", justifyContent: "center" } }} display={"flex"} gap={"10px"} alignItems={"center"}>
            <div className="heart-container">
              <RadioButtonCheckedIcon className="heart-icon" />
            </div>
            <Typography variant="sb20Bold" color={"white"}>
              {formatTime(countdown)}
            </Typography>
          </Grid>
        </Grid>

        {/* Main Content */}
        <Grid justifyContent={"center"} alignItems={"center"} padding={"20px 24px 20px 24px"} display={"flex"} flexDirection={"column"}>
          <Box display="flex" alignItems="center" gap={2} justifyContent="center" width="100%">
            <Typography my={1} variant="h32Bold" color={"#4C6EA2"} textAlign={"center"}>
              {currentQuestion}
            </Typography>
            
          {/* Audio control button */}
          {showQuestions && currentQuestion && (
            <Button
              onClick={handlePlayPause}
              sx={{
                minWidth: '40px',
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                padding: 0,
                backgroundColor: '#5C83BF',
                '&:hover': {
                  backgroundColor: '#4C6EA2',
                },
              }}
            >
              <FontAwesomeIcon
                icon={isPlaying ? "fa-solid fa-pause" : "fa-solid fa-play"}
                style={{ color: 'white', width: '16px', height: '16px' }}
              />
            </Button>
          )}
        </Box>
          
          <BarLoader
            color={"#0e7bff"}
            height={6}
            width={130}
            loading={buttonDisabled}
            css={{
              display: 'flex',
              margin: 'auto',
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          />
          
          <button
            onClick={handleToggleQuestions}
            className={`my-3 fw-bold text-blue ${buttonDisabled ? 'disabled-question' : ''}`}
            style={{ 
              border: "none", 
              background: "transparent", 
              zIndex: 1, 
              visibility: buttonDisabled ? "hidden" : "visible" 
            }}
            disabled={buttonDisabled}
          >
            Show a Question {showQuestions}
          </button>

          {/* Webcam and Audio Visualizer Container */}
          <Box ref={canvasParentRef} sx={{ position: "relative", height: "40vh", width: "50vw" }}>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Box 
                borderRadius={"100%"} 
                display={"flex"} 
                alignItems={"center"} 
                justifyContent={"center"}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "200px", 
                  height: "200px",
                  backgroundColor: "#4C6EA2", 
                  border: "1.5px solid #91A4BC"
                }}
              >
                {(!webcamVisible || showAvatar) && (
                  // show nothing NOT the avatar
                  <Box width={"100%"} height={"100%"} bgcolor={"#4C6EA2"} borderRadius={"100%"} />
                )}
              </Box>

              {videoAccess && (
                <Webcam
                  onUserMedia={handleScreenshots}
                  onUserMediaError={onMediaError}
                  audio={false}
                  ref={camRef}
                  screenshotFormat="image/jpeg"
                  style={{
                    zIndex: "1",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                  }}
                  className={`${webcamVisible ? "webcam-visible" : "webcam-invisible"}`}
                />
              )}

              <canvas
                ref={micCanvasRef}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "0",
                  background: "transparent",
                  filter: "drop-shadow(0px 5px 10px black)"
                }}
              />
            </Box>
          </Box>

          {/* Warning Message */}
          <Box 
            bgcolor={WarningMessage ? "rgba(76, 110, 162, 0.1)" : "transparent"} 
            alignSelf={"flex-end"} 
            borderRadius={"8px"} 
            padding={WarningMessage ? "12px" : "24px"} 
            gap={"16px"}
          >
            {WarningMessage && (
              <Typography color={"#4C6EA2"} variant="sb16Bold">{WarningMessage}</Typography>
            )}
          </Box>
        </Grid>

        {/* Footer */}
        <Grid display={"flex"} md={7} lg={6} xl={5} xs={10} gap={"10px"} alignItems={"center"} justifyContent={"space-between"} padding={"16px 24px 16px 24px"} borderRadius={"0px 0px 10px 10px"} sx={{ background: "#5C83BF" }}>
          <Grid sx={{ '@media (max-width: 450px)': { display: "flex", flexDirection: "column", justifyContent: "center" } }} display={"flex"} gap={"10px"} alignItems={"center"}>
            <div className="heart-container">
              <RadioButtonCheckedIcon className="heart-icon" />
            </div>
            <Typography variant="sb20Bold" color={"white"}>
              {formatTime(countdown)}
            </Typography>
          </Grid>
          
          <Grid display={"flex"} gap={"20px"} alignItems={"center"}>
            {!overviewData && videoAccess && (
              <Button
                onClick={() => { setShowAvatar(!showAvatar); toggleWebcam(); }}
              >
                {webcamVisible ? (
                  <Grid>
                    <SvgIcon component={videoIcon} sx={{ width: "56px", height: "56px" }} inheritViewBox />
                  </Grid>
                ) : (
                  <Box width={"56px"} height={"56px"} padding={"16px"} borderRadius={"100%"} bgcolor={"white"}>
                    <FontAwesomeIcon 
                      style={{ width: "24px", height: "24px", color: "#718BB1" }} 
                      icon="fa-solid fa-video-slash" 
                    />
                  </Box>
                )}
              </Button>
            )}
          </Grid>

          <Grid>
            <Button
              variant="withIcon"
              sx={{ border: "1.5px solid white" }}
              onClick={() => {
                if (!responseLoading) {
                  stopRecording();
                }
              }}
              disabled={responseLoading}
            >
              <SvgIcon component={stopCircle} sx={{ width: "24px", height: "24px" }} inheritViewBox />
              <Typography color={"white"} variant="sb16Bold">End Session</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};