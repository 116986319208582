import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';

const OnboardingSuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/my-profile');
    }, 15000);

    // Cleanup function in case the component unmounts before the timer has finished
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="prevent_overflow">
      <div className="success-page d-flex flex-column align-items-center justify-content-center content">
        <h1 className="text-success fw-bold">Onboarding Complete</h1>
        <h4 className="text-center">
          Congratulations! Your onboarding process has been completed successfully. You are now visible for PokaMind users, please add your availability to your appointments page.
        </h4>
        <h5 className="text-center">
          You will be redirected to your profile page in 15 seconds.
        </h5>
      </div>
    </div>
  );
};

export default OnboardingSuccessPage;