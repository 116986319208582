import React, { useState, useCallback } from 'react';
import { useNavigate} from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import Webcam from 'react-webcam';
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, SvgIcon, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { db, doc, getDoc } from "../../Firebase";
import { tierLimits } from './mySessionUtils';
import { useMySessionLogic } from './useMySessionLogic';
import { formatTime } from './mySessionUtils';
import { RecordingUI } from './RecordingUI';
import MediaTest from "./MediaTest";
import LoadingSpinner from "../shared/LoadingSpinner";
import SessionResponseSpinner from "../shared/SessionResponseSpinner";
import { useUser } from '../../contexts/UserContext';
import { useErrorBoundary } from "react-error-boundary";
import audiofile from '../../start.wav';
import audioending from '../../end.wav';
import Avatar from '../../data/avatar.png';
import { TopicSelection } from './TopicSelection';
import "./MySession.scss";

const MySession = () => {
  const navigate = useNavigate();
  const { user, tier,role } = useUser();
  const { showBoundary } = useErrorBoundary();
  // new state for topic selection
  const [selectedTopic, setSelectedTopic] = useState(null);
  // if not admin user, do not show topic selection
    const [showTopicSelection, setShowTopicSelection] = useState(true);
  
  // Add states for session limits
  const [limitError, setLimitError] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const {
    loading,
    responseLoading,
    recording,
    startRecording: originalStartRecording,
    audioCanvasRef,
    audioRef,
    audioEndingRef,
    micCanvasRef,
    camRef,
    canvasParentRef,
    countdown,
    currentQuestion,
    buttonDisabled,
    showQuestions,
    toggleQuestions,
    WarningMessage,
    webcamVisible,
    showAvatar,
    videoAccess,
    setVideoAccess,
    toggleWebcam,
    setShowAvatar,
    overviewData,
    stopRecording,
    resultError,
    handleScreenshots,
    loadVisualizer
  } = useMySessionLogic();

  const checkSessionLimits = useCallback(async () => {
    const userRef = doc(db, "users", user.uid);
    const todayStr = new Date().toISOString().split('T')[0];
    const weekStart = new Date();
    weekStart.setDate(weekStart.getDate() - weekStart.getDay() + (weekStart.getDay() === 0 ? -6 : 1));
    const weekStartStr = weekStart.toISOString().split('T')[0];
  
    console.log('DEBUG - Checking limits for:', {
      userId: user.uid,
      tier: tier,
      today: todayStr,
      weekStart: weekStartStr
    });
  
    try {
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        console.error('DEBUG - User document not found');
        showBoundary(new Error("User not found"));
        return { allowed: false };
      }
      const data = userDoc.data();
      const sessionCount = data.session_count || {};
      const thisWeek = sessionCount.this_week || {};
      const thisDay = sessionCount.this_day || {};
  
      console.log('DEBUG - Firebase Data:', {
        sessionCount,
        thisWeek,
        thisDay,
        tierLimits: tierLimits[tier]
      });
  
      // Get current counts regardless of reset status
      const sessionsThisWeek = thisWeek.count || 0;
      const sessionsToday = thisDay.count || 0;
      
      console.log('DEBUG - Current Usage:', {
        sessionsThisWeek,
        sessionsToday,
        weekStart: thisWeek.week_start,
        expectedWeekStart: weekStartStr,
        dayDate: thisDay.date
      });
  
      // For free tier, check if there's already a session today
      if (tier === 'free' && tierLimits.free.dailyLimit) {
        if (thisDay.date === todayStr && thisDay.totalDuration > 0) {
          console.log('DEBUG - Free tier daily limit reached - already has session today');
          return {
            allowed: false,
            reason: "Weekly Limit Reached",
            message: "You've reached your daily session limit. Upgrade to Premium for unlimited sessions!",
            showUpgrade: true
          };
        }
      }
  
      // Check weekly limit for all tiers
      // For free tier: even if week needs reset, if they've already had a session this week, don't allow more
      if (tier === 'free' && sessionsThisWeek > 0) {
        console.log('DEBUG - Free tier weekly limit reached:', {
          current: sessionsThisWeek,
          limit: tierLimits[tier].weeklyLimit
        });
        return {
          allowed: false,
          reason: "Weekly Limit Reached",
          message: "You've reached your weekly session limit. Upgrade to Premium for more sessions!",
          showUpgrade: true
        };
      }
  
      // For premium tier, check weekly limit
      if (tier === 'premium' && !thisWeek.week_start !== weekStartStr) {
        if (sessionsThisWeek >= tierLimits.premium.weeklyLimit) {
          console.log('DEBUG - Premium tier weekly limit reached');
          return {
            allowed: false,
            reason: "Weekly Limit Reached",
            message: "You've reached your weekly session limit.",
            showUpgrade: false
          };
        }
      }
  
      // Check premium tier duration limits
      if (tier === 'premium' && thisDay.date === todayStr) {
        const totalDurationToday = thisDay.totalDuration || 0;
        console.log('DEBUG - Checking premium duration:', {
          current: totalDurationToday,
          limit: tierLimits.premium.dailyDurationLimit
        });
        
        if (totalDurationToday >= tierLimits.premium.dailyDurationLimit) {
          return {
            allowed: false,
            reason: "Daily Duration Limit Reached",
            message: "You've reached your daily session duration limit.",
            showUpgrade: false
          };
        }
      }
  
      console.log('DEBUG - All checks passed, session allowed');
      return { allowed: true };
    } catch (error) {
      console.error("DEBUG - Error checking session limits:", error);
      showBoundary(error);
      return { allowed: false };
    }
  }, [user.uid, tier, showBoundary]);


  
  const startRecording = async () => {
    console.log('DEBUG - Attempting to start recording');
    const limitCheck = await checkSessionLimits();
    console.log('DEBUG - Limit check result:', limitCheck);
    
    if (!limitCheck.allowed) {
      setLimitError({
        reason: limitCheck.reason,
        message: limitCheck.message
      });
      if (limitCheck.showUpgrade) {
        setShowUpgradeModal(true);
      }
      return;
    }
    originalStartRecording(selectedTopic);
  };

  // Upgrade handling
  const handleUpgrade = () => {
    navigate('/plans');
  };

    // Add topic selection handler
    const handleTopicSelect = (topicId) => {
      setSelectedTopic(topicId);
      setShowTopicSelection(false);
    };

  // Limit Error Modal
  const LimitErrorModal = () => (
    <Dialog open={!!limitError} onClose={() => setLimitError(false)}>
      <DialogTitle>
        <Typography variant="h6" color="primary.main">
          {limitError.reason}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {limitError.message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setLimitError(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Upgrade Modal
  const UpgradeModal = () => (
    <Dialog open={showUpgradeModal} onClose={() => setShowUpgradeModal(false)}>
      <DialogTitle>
        <Typography variant="h6" color="primary.main">
          Upgrade to Premium
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          Unlock unlimited daily sessions and extended session duration with our Premium plan!
        </Typography>
        <Box mt={2}>
          <Typography variant="subtitle1" color="primary.main" gutterBottom>
            Premium Benefits:
          </Typography>
          <ul>
            <li>12 sessions per week</li>
            <li>No daily session limits</li>
            <li>10-minute session duration</li>
          </ul>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowUpgradeModal(false)} color="inherit">
          Maybe Later
        </Button>
        <Button 
          onClick={handleUpgrade} 
          variant="contained" 
          color="primary"
          sx={{
            backgroundColor: "#5C83BF",
            '&:hover': {
              backgroundColor: "#4C6EA2"
            }
          }}
        >
          Upgrade Now
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (loading) {
    return <LoadingSpinner className="bigger_height" />;
  }

  if (responseLoading) {
    return <SessionResponseSpinner />;
  }

    // if user role is admin, show it , if not admin do not show it Show topic selection first
    if (role === 'admin' && showTopicSelection) {
      return (
        <div className="prevent_overflow">
          <TopicSelection onTopicSelect={handleTopicSelect} />
        </div>
      );
    }

  return (
    <div className="prevent_overflow">
      <LimitErrorModal />
      <UpgradeModal />
      
      {!recording && !loading && (
        <div>
          {resultError && (
            <Typography
              variant="h32Bold"
              color="primary.darkerBlue"
              className="text-center comfortaa_font mb-4"
            >
              Error: Analysis unclear. Please try again and wait for at least 15 seconds before stopping
            </Typography>
          )}
          <MediaTest 
            onContinue={startRecording} 
            videoAccess={videoAccess} 
            setVideoAccess={setVideoAccess} 
          />
        </div>
      )}

      {recording && (
        <>
          <RecordingUI
            countdown={countdown}
            currentQuestion={currentQuestion}
            buttonDisabled={buttonDisabled}
            showQuestions={showQuestions}
            toggleQuestions={toggleQuestions}
            canvasParentRef={canvasParentRef}
            webcamVisible={webcamVisible}
            showAvatar={showAvatar}
            videoAccess={videoAccess}
            camRef={camRef}
            micCanvasRef={micCanvasRef}
            audioCanvasRef={audioCanvasRef}
            audioRef={audioRef}
            audioEndingRef={audioEndingRef}
            WarningMessage={WarningMessage}
            overviewData={overviewData}
            toggleWebcam={toggleWebcam}
            setShowAvatar={setShowAvatar}
            stopRecording={stopRecording}
            responseLoading={responseLoading}
            handleScreenshots={handleScreenshots}
            formatTime={formatTime}
          />
          <div className="d-none justify-content-center align-items-center">
            <canvas ref={audioCanvasRef} width={window.innerWidth}></canvas>
            <audio 
              ref={audioRef} 
              src={audiofile} 
              preload="auto" 
              onLoadedMetadata={loadVisualizer}
            ></audio>
            <audio 
              ref={audioEndingRef} 
              src={audioending}
              preload="auto"
            ></audio>
          </div>
        </>
      )}
    </div>
  );
};

export default MySession;