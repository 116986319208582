import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Select, MenuItem } from "@mui/material";
import axios from 'axios';
import { URL } from '../../Helpers';
import { useLocation } from 'react-router-dom';

const SUPPORTED_LANGUAGES = {
  en: 'English',
  ar: 'Arabic',
  es: 'Spanish',
  de: 'German',
  sv: 'Swedish',
  // Add more languages as needed
};

function TranslateComponent() {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  const [translations, setTranslations] = useState(() => JSON.parse(localStorage.getItem('translations') || '{}'));
  const location = useLocation();
  const originalTexts = useRef(new Map());
  const isInitialMount = useRef(true);
  const translationInProgress = useRef(false);

  const translateWebsite = useCallback(async (targetLanguage) => {
    if (translationInProgress.current) return;
    translationInProgress.current = true;

    if (targetLanguage === 'en') {
      resetToOriginalTexts();
      setLanguage('en');
      localStorage.setItem('language', 'en');
      translationInProgress.current = false;
      return;
    }

    const textNodes = getTextNodes(document.body);
    const textsToTranslate = [];

    textNodes.forEach(node => {
      const text = node.textContent.trim();
      if (text) {
        if (!originalTexts.current.has(node)) {
          originalTexts.current.set(node, text);
        }
        const originalText = originalTexts.current.get(node);
        if (!translations[targetLanguage] || !translations[targetLanguage][originalText]) {
          textsToTranslate.push(originalText);
        }
      }
    });

    if (textsToTranslate.length === 0) {
      applyTranslations(targetLanguage);
      translationInProgress.current = false;
      return;
    }

    try {
      const response = await axios.post(`${URL}/translate`, {
        texts: textsToTranslate,
        targetLanguage,
        sourceLanguage: 'en'  // Always translate from English
      });

      const newTranslations = {
        ...translations,
        [targetLanguage]: {
          ...(translations[targetLanguage] || {}),
          ...response.data
        }
      };
      setTranslations(newTranslations);
      localStorage.setItem('translations', JSON.stringify(newTranslations));

      applyTranslations(targetLanguage);

      setLanguage(targetLanguage);
      localStorage.setItem('language', targetLanguage);
    } catch (error) {
      console.error('Translation error:', error);
      setLanguage(prevLanguage => {
        localStorage.setItem('language', prevLanguage);
        return prevLanguage;
      });
    } finally {
      translationInProgress.current = false;
    }
  }, [translations]);

  const applyTranslations = useCallback((targetLanguage) => {
    originalTexts.current.forEach((originalText, node) => {
      if (translations[targetLanguage] && translations[targetLanguage][originalText]) {
        node.textContent = translations[targetLanguage][originalText];
      }
    });
  }, [translations]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage && storedLanguage !== language) {
      setLanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      translateWebsite(language);
    }
  }, [language, translateWebsite]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setTimeout(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage && storedLanguage !== language) {
          setLanguage(storedLanguage);
        } else {
          translateWebsite(language);
        }
      }, 0);
    }
  }, [location.pathname, translateWebsite, language]);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    translateWebsite(newLanguage);
  };

  const resetToOriginalTexts = () => {
    originalTexts.current.forEach((originalText, node) => {
      node.textContent = originalText;
    });
  };

  return (
    <Select value={language} onChange={handleLanguageChange}>
      {Object.entries(SUPPORTED_LANGUAGES).map(([code, name]) => (
        <MenuItem key={code} value={code}>{name}</MenuItem>
      ))}
    </Select>
  );
}

function getTextNodes(node) {
  const textNodes = [];
  const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, null, false);

  let currentNode;
  while (currentNode = walker.nextNode()) {
    if (currentNode.textContent.trim() !== '' && !isHiddenNode(currentNode)) {
      textNodes.push(currentNode);
    }
  }

  return textNodes;
}

function isHiddenNode(node) {
  if (node.nodeType === Node.TEXT_NODE && node.parentElement) {
    node = node.parentElement;
  }

  let current = node;
  while (current && current !== document.body) {
    if (current.nodeType === Node.ELEMENT_NODE) {
      const style = window.getComputedStyle(current);
      if (style.display === 'none' || style.visibility === 'hidden') {
        return true;
      }
    }
    current = current.parentNode;
  }
  return false;
}

export default TranslateComponent;