import React, { useState, useEffect, useCallback, useRef } from "react";
import { 
  Typography, Button, Card, CardContent, CardMedia, 
  CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions,
  Container, Box, IconButton, Snackbar, Fade, Slider
} from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import LockIcon from '@mui/icons-material/Lock';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import CloseIcon from '@mui/icons-material/Close';
import "./CalmCorner.scss";
import "./style.scss";
import { useUser } from "../contexts/UserContext";
import { storage, ref, listAll, getDownloadURL } from "../Firebase";
import anexityImage from '../data/anexity.png';
import feelosophyImage from '../data/feelosophy.png';
import distortionsImage from '../data/distortions.png';
import takebreath from '../data/other.png';

const CalmCorner = () => {
  const { tier } = useUser();
  const [audioTracks, setAudioTracks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTrack, setCurrentTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [openPaywall, setOpenPaywall] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showPlayer, setShowPlayer] = useState(false);
  const audioRef = useRef(new Audio());
  const progressIntervalRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationRef = useRef(null);
  const [blobs, setBlobs] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const relaxationImages = [anexityImage, feelosophyImage, distortionsImage, takebreath];
  const lavaLampRef = useRef(null);
  const blobsRef = useRef([]);
  const playerContentRef = useRef(null);
  const [showExitButton, setShowExitButton] = useState(false);
  const setupLavaLamp = () => {
    const container = lavaLampRef.current;
    if (!container) return;
  
    // Create blobs
    for (let i = 0; i < 5; i++) {
      const blob = document.createElement('div');
      blob.className = 'blob';
      container.appendChild(blob);
      blobsRef.current.push(blob);
    }
  };
  
  const animateLavaLamp = () => {
    if (!analyserRef.current) return;
  
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
  
    const animate = () => {
      analyserRef.current.getByteFrequencyData(dataArray);
  
      blobsRef.current.forEach((blob, index) => {
        const amplitude = dataArray[Math.floor(bufferLength / blobsRef.current.length) * index] / 255;
        const size = 100 + amplitude * 100;
        const x = Math.sin(Date.now() * 0.001 + index) * 100 + 150;
        const y = Math.cos(Date.now() * 0.002 + index) * 100 + 150;
  
        blob.style.width = `${size}px`;
        blob.style.height = `${size}px`;
        blob.style.left = `${x}px`;
        blob.style.top = `${y}px`;
        blob.style.opacity = 0.5 + amplitude * 0.5;
      });
  
      requestAnimationFrame(animate);
    };
  
    animate();
  };
  
  const cleanupLavaLamp = () => {
    const container = lavaLampRef.current;
    if (container) {
      container.innerHTML = '';
    }
    blobsRef.current = [];
  };
  const fetchAudioTracks = useCallback(async () => {
    const listRef = ref(storage, 'mindfull');
    try {
      const res = await listAll(listRef);
      const trackPromises = res.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        const duration = await getAudioDuration(url);
        return { name: itemRef.name, url, duration };
      });
      const tracks = await Promise.all(trackPromises);
      setAudioTracks(tracks);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching audio tracks:", error);
      setError("Failed to load audio tracks. Please try again later.");
      setLoading(false);
    }
  }, []);
  
  // Helper function to get audio duration
  const getAudioDuration = (url) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      audio.addEventListener('loadedmetadata', () => {
        resolve(Math.round(audio.duration / 60)); // Duration in minutes, rounded
      });
      audio.addEventListener('error', reject);
      audio.src = url;
    });
  };
  useEffect(() => {
    fetchAudioTracks();
    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, [fetchAudioTracks]);

  useEffect(() => {
    const calmingColors = ['#bdd1e0', '#abc1d5', '#96b0d0', '#d6bc8a', '#d0c1a4'];
    // Create initial blobs
    setBlobs(Array(5).fill().map((_, i) => ({
      id: i,
      style: {
        backgroundColor: calmingColors[Math.floor(Math.random() * calmingColors.length)],
        animationDuration: `
          ${Math.random() * 15 + 25}s,
          ${Math.random() * 25 + 35}s,
          ${Math.random() * 15 + 20}s
        `,
        animationTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)',
      }
    })));

    // Gradually adjust animation properties for smoother transitions
    const intervalId = setInterval(() => {
      setBlobs(prevBlobs => prevBlobs.map(blob => {
        const newColor = calmingColors[Math.floor(Math.random() * calmingColors.length)];
        return {
          ...blob,
          style: {
            ...blob.style,
            backgroundColor: newColor,
            animationDuration: `
              ${parseFloat(blob.style.animationDuration.split(',')[0]) + (Math.random() * 2 - 1)}s,
              ${parseFloat(blob.style.animationDuration.split(',')[1]) + (Math.random() * 2 - 1)}s,
              ${parseFloat(blob.style.animationDuration.split(',')[2]) + (Math.random() * 2 - 1)}s
            `,
          }
        };
      }));
    }, 10000);

    // Cleanup function
    return () => {
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array means this effect runs once on mount


  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleDurationChange = () => {
      setDuration(audio.duration);
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('durationchange', handleDurationChange);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('durationchange', handleDurationChange);
    };
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };


  const startProgressInterval = () => {
    progressIntervalRef.current = setInterval(() => {
      const audio = audioRef.current;
      if (audio.duration) {
        setProgress((audio.currentTime / audio.duration) * 100);
      }
    }, 1000);
  };

  const stopProgressInterval = () => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }
  };

  const setupAudioAnalyser = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    const source = audioContextRef.current.createMediaElementSource(audioRef.current);
    analyserRef.current = audioContextRef.current.createAnalyser();
    source.connect(analyserRef.current);
    analyserRef.current.connect(audioContextRef.current.destination);
    analyserRef.current.fftSize = 256;
  };

  const drawWaveform = () => {
    if (!analyserRef.current) return;

    const canvas = document.getElementById('waveform');
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      animationRef.current = requestAnimationFrame(draw);

      analyserRef.current.getByteFrequencyData(dataArray);

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(74, 144, 226, 0.2)';
      ctx.strokeStyle = '#4a90e2';
      ctx.lineWidth = 2;

      ctx.beginPath();
      ctx.moveTo(0, canvas.height / 2);

      for (let i = 0; i < bufferLength; i++) {
        const value = dataArray[i] / 255;
        const x = (i / bufferLength) * canvas.width;
        const y = (0.5 + value / 2) * canvas.height;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      }

      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.fill();
      ctx.stroke();
    };

    draw();
  };

  const handlePlay = (track, index) => {
    if (tier !== "premium" && index > 0) {
      setOpenPaywall(true);
      return;
    }
  
    const audio = audioRef.current;
  
    if (currentTrack && currentTrack.url === track.url) {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
      } else {
        audio.play();
        setIsPlaying(true);
      }
    } else {
      audio.src = track.url;
      audio.play().then(() => {
        setCurrentTrack(track);
        setIsPlaying(true);
        startProgressInterval();
        setShowPlayer(true);
      }).catch(error => {
        console.error("Error playing audio:", error);
        setError("Failed to play audio. Please try again.");
      });
    }
    
    // Always show the player when a track is selected
    setShowPlayer(true);
    setupLavaLamp();
    animateLavaLamp();
  };

  const handleClosePaywall = () => setOpenPaywall(false);

  const toggleMute = () => {
    const newMutedState = !isMuted;
    setIsMuted(newMutedState);
    if (audioRef.current) {
      audioRef.current.muted = newMutedState;
    }
  };

  const handleVolumeChange = (event, newValue) => {
    const newVolume = newValue / 100;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  };

  const handleCloseError = () => setError(null);

  const handleClosePlayer = () => {
    audioRef.current.pause();
    setIsPlaying(false);
    setShowPlayer(false);
    setProgress(0);
    setShowExitButton(false);
    cleanupLavaLamp();
  };

  useEffect(() => {
    const audio = audioRef.current;
  
    const handleEnded = () => {
      setIsPlaying(false);
      setProgress(0);
      cleanupLavaLamp();
    };
  
    const handleError = (e) => {
      console.error("Audio playback error:", e);
      setError(`Audio playback error: ${e.target.error.message}`);
    };
  
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('error', handleError);
  
    return () => {
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('error', handleError);
    };
  }, []);

  return (
    <div className={`calm-corner ${showPlayer ? 'playing' : ''}`}>
      <div className="background-blur"></div>
      <div className="prevent_overflow">
        <Container maxWidth="lg" className={`content ${showPlayer ? 'faded' : ''}`}>
          <Box my={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
            <Typography variant="h32Bold" gutterBottom color="primary.darkerBlue">
              Calm Corner
            </Typography>
            <Typography variant="subtitle1" gutterBottom color="otherColors.mainBodyDark">
              Relax and unwind with our soothing audio tracks
            </Typography>
          </Box>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" >
              <CircularProgress />
            </Box>
          ) : audioTracks.length === 0 ? (
            <Typography>No audio tracks found. Please login or sign up then try again.</Typography>
          ) : (
            <div className="track-grid">
              {audioTracks.map((track, index) => (
                <Fade key={index} in={true} timeout={500 * (index + 1)}>
                  <Card className="track-card">
                    <CardMedia
                      component="img"
                      height="180"
                      image={relaxationImages[index % relaxationImages.length]}
                      alt="relaxation"
                      className="track-image"
                    />
                    <div className="duration-bubble">{track.duration} min</div>
                    <CardContent>
                      <Typography variant="h6" component="div" className="track-title">
                        {track.name.replace('.wav', '')}
                      </Typography>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={
                            tier !== "premium" && index > 0 ? <LockIcon /> :
                            currentTrack && currentTrack.url === track.url && isPlaying ? <PauseIcon /> : <PlayArrowIcon />
                          }
                          onClick={() => handlePlay(track, index)}
                          fullWidth
                        >
                          {tier !== "premium" && index > 0 ? "Locked" :
                           currentTrack && currentTrack.url === track.url && isPlaying ? "Pause" : "Play"}
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Fade>
              ))}
            </div>
          )}
        </Container>
        {showPlayer && (
          <div className="player-overlay">
            <div className="lava-lamp">
              {blobs.map(blob => (
                <div key={blob.id} className="blob" style={blob.style}></div>
              ))}
            </div>
            <div className="player-content">
            <IconButton 
              onClick={handleClosePlayer} 
              color="inherit" 
              size="large"
              className="close-button"
              aria-label="close player"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
              
              {blobs.map(blob => (
                <div key={blob.id} className="blob-player" style={blob.style} />
              ))}

              <canvas id="waveform" width="300" height="300"></canvas>
              <Typography variant="h4" className="track-name">
                {currentTrack?.name.replace('.wav', '')}
              </Typography>
              <Box width="80%" mx="auto" mb={2}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography className="track-name" variant="body2">{formatTime(currentTime)}</Typography>
                  <Slider
                    value={progress}
                    onChange={(_, newValue) => {
                      const audio = audioRef.current;
                      if (audio) {
                        audio.currentTime = (newValue / 100) * audio.duration;
                        setProgress(newValue);
                      }
                    }}
                    sx={{ mx: 2, flexGrow: 1 }}
                  />
                  <Typography className="track-name" variant="body2">{formatTime(duration)}</Typography>
                </Box>
              </Box>
              <div className="player-controls">
                <IconButton onClick={() => handlePlay(currentTrack)} color="primary" size="large">
                  {isPlaying ? <PauseIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />}
                </IconButton>
              </div>
              <Box width="200px" mx="auto" mt={2}>
                <Slider
                  value={volume * 100}
                  onChange={handleVolumeChange}
                  aria-labelledby="volume-slider"
                />
              </Box>
            </div>
          </div>
        )}
      </div>

      <Dialog open={openPaywall} onClose={handleClosePaywall}>
        <DialogTitle>Premium Content</DialogTitle>
        <DialogContent>
          <Typography>
            This track is only available for premium users. Upgrade your account to access our full library of relaxation tracks.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaywall} color="primary">Close</Button>
          <Button onClick={() => window.location.href = "/plans"} variant="contained" color="primary">Upgrade to Premium</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        message={error}
        action={
          <Button color="secondary" size="small" onClick={handleCloseError}>
            Dismiss
          </Button>
        }
      />
    </div>
  );
};


export default CalmCorner;