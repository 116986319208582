import React, { useState, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import Recorder from 'recorder-js';
import { useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Box,
  LinearProgress, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  useTheme,
  useMediaQuery
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import MicIcon from '@mui/icons-material/Mic';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const MediaTest = ({ onSuccess, onHide, show, value, onContinue, videoAccess, setVideoAccess }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [micStatus, setMicStatus] = useState(false);
  const [camStatus, setCamStatus] = useState(false);
  const [amplitude, setAmplitude] = useState(0);
  const [canContinue, setCanContinue] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const webcamRef = useRef(null);
  const audioRecorderRef = useRef(null);
  const audioStreamRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const recorder = new Recorder(audioContext);
    audioRecorderRef.current = recorder;
    
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        recorder.init(stream);
        recorder.start();
        audioStreamRef.current = stream;

        const analyser = audioContext.createAnalyser();
        audioContext.createMediaStreamSource(stream).connect(analyser);
        const data = new Uint8Array(analyser.frequencyBinCount);
        
        const update = () => {
          analyser.getByteFrequencyData(data);
          const value = data.reduce((acc, val) => acc + val, 0);
          const avgAmplitude = value / data.length;
          setAmplitude(avgAmplitude * 5);
          setMicStatus(avgAmplitude > 0);
          if (avgAmplitude > 0.01) setCanContinue(true);
          requestAnimationFrame(update);
        };
        update();
      })
      .catch(console.error);

    return () => {
      if (audioRecorderRef.current) {
        audioRecorderRef.current.stop();
        audioRecorderRef.current = null;
      }
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach(track => track.stop());
        audioStreamRef.current = null;
      }
      if (audioContext.state === 'running') {
        audioContext.close();
      }
    };
  }, []);

  const handleCameraToggle = () => {
    if (videoAccess) {
      setOpenDialog(true);
    } else {
      setCamStatus(!camStatus);
      setVideoAccess(prev => !prev);
    }
  };

  const handleDialogClose = (confirm) => {
    if (confirm) {
      setCamStatus(!camStatus);
      setVideoAccess(false);
    }
    setOpenDialog(false);
  };

  return (
    <Box sx={{ 
      width: '100%', 
      minHeight: '100vh',
      p: { xs: 1, sm: 2, md: 3 },
      background: '#FFF7F1'
    }}>
      <Grid 
        container 
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ mb: { xs: 3, md: 5 } }}
      >
        <Grid xs={12} sm={10} md={8} lg={6}>
          <Box sx={{
            background: '#FFFFFF',
            border: '1px solid #E3DBD5',
            borderRadius: '10px',
            overflow: 'hidden'
          }}>
            {/* Header */}
            <Box sx={{
              background: '#5C83BF',
              p: { xs: 2, md: 3 },
            }}>
              <Typography 
                variant={isMobile ? "h6" : "h5"} 
                color="white" 
                fontWeight="bold"
              >
                Instructions
              </Typography>
            </Box>

            {/* Content */}
            <Box sx={{
              p: { xs: 2, md: 4 },
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              alignItems: 'center'
            }}>
              <Typography 
                variant={isMobile ? "body1" : "h6"}
                color="#3F444B"
                align="center"
                sx={{ fontWeight: 600 }}
              >
                Please allow access to both your microphone and camera for better accuracy. 
                You can say "myPoka" once to test your mic.
              </Typography>

              {/* Camera Section */}
              <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'center',
                gap: 2,
                justifyContent: 'center'
              }}>
                {videoAccess && (
                  <Box sx={{
                    width: isMobile ? '100%' : '50%',
                    borderRadius: '10px',
                    overflow: 'hidden'
                  }}>
                    <Webcam
                      ref={webcamRef}
                      audio={false}
                      width="100%"
                      onUserMediaError={() => {
                        console.log("Camera access blocked");
                        setVideoAccess(false);
                      }}
                      style={{
                        borderRadius: '10px',
                        visibility: videoAccess ? "visible" : "hidden",
                      }}
                    />
                  </Box>
                )}

                <Button
                  onClick={handleCameraToggle}
                  variant="contained"
                  sx={{
                    bgcolor: '#4C6EA2',
                    '&:hover': { bgcolor: '#3d5a84' },
                    minWidth: isMobile ? '100%' : 'auto',
                    py: 1.5
                  }}
                >
                  <PhotoCameraIcon sx={{ mr: isMobile ? 1 : 0.5 }} />
                  <Typography variant="body1">
                    {videoAccess ? "Disable Video" : "Enable Video"}
                  </Typography>
                </Button>
              </Box>

              {/* Audio Level Indicator */}
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <LinearProgress 
                  variant="determinate" 
                  value={amplitude}
                  sx={{
                    width: isMobile ? '90%' : '45%',
                    height: 8,
                    borderRadius: 1,
                    bgcolor: '#E5E5E5',
                    '& .MuiLinearProgress-bar': {
                      bgcolor: '#4C6EA2'
                    }
                  }}
                />
              </Box>

              {/* Instructions */}
              <Box sx={{ textAlign: 'center' }}>
                <Typography 
                  variant={isMobile ? "body1" : "h6"}
                  color="#3F444B"
                  sx={{ fontWeight: 600, mb: 1 }}
                >
                  After testing your mic and camera, you are ready to go.
                </Typography>
                <Typography 
                  variant="body2"
                  color="#3F444B"
                  sx={{ fontStyle: 'italic' }}
                >
                  Click the button to start your session!
                </Typography>
              </Box>

              {/* Start Button */}
              <Button
                onClick={onContinue}
                variant="contained"
                sx={{
                  bgcolor: '#4C6EA2',
                  '&:hover': { bgcolor: '#3d5a84' },
                  color: 'white',
                  py: 1.5,
                  px: 3,
                  mt: 2
                }}
              >
                <ArrowForwardIcon sx={{ mr: 1 }} />
                <Typography variant="body1" sx={{ fontWeight: 600 }}>
                  Start Talking
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => handleDialogClose(false)}
        sx={{
          '& .MuiDialog-paper': {
            width: isMobile ? '90%' : 'auto',
            m: isMobile ? 2 : 3
          }
        }}
      >
        <DialogTitle sx={{ 
          fontSize: isMobile ? '1.2rem' : '1.5rem',
          fontWeight: 600 
        }}>
          Disable Camera?
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: isMobile ? '0.9rem' : '1rem' }}>
            Are you sure you would like to disable the camera? The communication styles detection will be noticeably less accurate and it might affect the analysis given.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={() => handleDialogClose(false)}
            sx={{ color: '#4C6EA2' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => handleDialogClose(true)}
            variant="contained"
            sx={{ 
              bgcolor: '#4C6EA2',
              '&:hover': { bgcolor: '#3d5a84' }
            }}
          >
            Disable
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MediaTest;