import React, { useState, useEffect } from 'react';
import { URL } from '../../Helpers';

const UserDisplay = ({ user }) => {
  const [userName, setUserName] = useState(
    // Initialize state with session storage value or fallback
    sessionStorage.getItem(`userName_${user?.uid}`) ||
    user?.email?.split('@')[0] || 'Guest'
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      // Check if we need to fetch (no session storage or older than 1 hour)
      const lastFetch = sessionStorage.getItem(`lastFetch_${user.uid}`);
      const now = Date.now();
      if (lastFetch && now - parseInt(lastFetch) < 3600000) {
        return; // Use cached value if less than 1 hour old
      }

      setIsLoading(true);
      try {
        const response = await fetch(`${URL}/getProfile/${user.uid}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.accessToken}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setUserName(data.displayName);
        
        // Save to session storage
        sessionStorage.setItem(`userName_${user.uid}`, data.displayName);
        sessionStorage.setItem(`lastFetch_${user.uid}`, now.toString());
      } catch (err) {
        console.error("Error fetching name:", err);
        // Fallback to email or 'Guest' if fetch fails
        const fallbackName = user.email ? user.email.split('@')[0] : 'Guest';
        setUserName(fallbackName);
        sessionStorage.setItem(`userName_${user.uid}`, fallbackName);
      } finally {
        setIsLoading(false);
      }
    };
    if(user)
      fetchUserProfile();
  }, [user]);

  return (
    <span>
      {userName}
      {isLoading && <span className="loading-indicator"> ...</span>}
    </span>
  );
};

export default UserDisplay;