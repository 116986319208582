import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailLink, sendSignInLinkToEmail, GoogleAuthProvider, signInWithPopup, isSignInWithEmailLink, getAdditionalUserInfo } from 'firebase/auth';
import { useNavigate, useLocation, Link as RouterLink } from "react-router-dom";
import { useUser } from '../contexts/UserContext';
import { auth } from "../Firebase";
import { URL, createNewUserDocument, fetchUserRole, pingBackend } from '../Helpers';
import LoadingSpinner from './shared/LoadingSpinner';
import googleIcon from '../data/icons8-google.svg';
import { 
  Grid, 
  Typography, 
  Alert, 
  Button, 
  TextField, 
  Box, 
  Container,
  Paper,
  Fade,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Link,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import leftImage from '../data/authImage1.png';
import rightImage from '../data/authImage2.png';
import SlackIcon from '../data/SlackIcon';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: '#5C83BF',
  color: 'white',
  width: '100%',
  maxWidth: 400,
  margin: '0 auto'
}));

const StyledTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    backgroundColor: 'white',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'white',
    },
    '&.Mui-focused': {
      backgroundColor: 'white',
    }
  },
  '& .MuiFilledInput-input': {
    color: '#333',
  },
  '& .MuiInputLabel-root': {
    color: '#666',
  },
  '& .Mui-focused .MuiInputLabel-root': {
    color: '#5C83BF',
  }
});

const AuthButton = styled(Button)({
  backgroundColor: 'white',
  color: '#5C83BF',
  textTransform: 'none',
  fontSize: '16px',
  padding: '10px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

const SocialButton = styled(Button)({
  backgroundColor: 'white',
  color: '#333',
  textTransform: 'none',
  fontSize: '16px',
  padding: '10px 20px',
  border: '1px solid #e0e0e0',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    border: '1px solid #e0e0e0',
  },
});

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '320px',
    height: '320px',
    borderRadius: '50%',
    backgroundColor: 'rgba(92, 131, 191, 0.1)',
    zIndex: 0
  }
}));

// Add this styled component with your existing styled components
const SlackButton = styled(SocialButton)({
  backgroundColor: '#FFFFFF',
  color: 'black',
  '&:hover': {
    backgroundColor: '#371536',
    border: '1px solid #371536',
    color: 'white'
  }
});

const AuthSignup = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const { user, setRole, role } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const from = location.state?.from?.pathname || "/get-started";

  useEffect(() => {
    if (user && role) {
      navigate(from, { replace: true });
    }
  }, [navigate, user, role, from]);

  useEffect(() => {
    const handleEmailLink = async () => {
      if (isSignInWithEmailLink(getAuth(), window.location.href)) {
        setLoading(true);
        const email = window.localStorage.getItem('emailForSignIn');
        if (email) {
          try {
            const result = await signInWithEmailLink(auth, email, window.location.href);
            const details = getAdditionalUserInfo(result);
            if (details.isNewUser) {
              await createNewUserDocument(result.user.accessToken, result.user.email);
            }
            window.localStorage.removeItem('emailForSignIn');
            setRole(await fetchUserRole(result.user));
            pingBackend(result.user.accessToken);
            navigate(from, { replace: true });
          } catch (error) {
            console.error('Email link sign in error:', error);
            setLoading(false);
          }
        }
      }
    };

    handleEmailLink();
  }, [navigate, from, setRole]);

  const signInWithGoogle = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const details = getAdditionalUserInfo(result);
      if (details.isNewUser) {
        await createNewUserDocument(result.user.accessToken, result.user.email);
      }
      setRole(await fetchUserRole(result.user));
      await pingBackend(result.user.accessToken);
      navigate(from, { replace: true });
    } catch (error) {
      console.error('Google sign in error:', error);
      setLoading(false);
    }
  };

  const signInWithSlack = async () => {
    try {
      // Store current path for redirect after auth
      localStorage.setItem('slackAuthRedirect', window.location.pathname);
      
      // Redirect to backend OAuth start endpoint (make sure URL points to your Cloud Run instance)
      window.location.href = `${URL}/slack/oauth/start`;
    } catch (error) {
      console.error('Slack sign in error:', error);
    }
  };
  const handleSignIn = async (event) => {
    event.preventDefault();
    if (!email || !emailValid) return;
    
    setLoading(true);
    try {
      const actionCodeSettings = {
        url: `${window.location.origin}/auth`,
        handleCodeInApp: true,
      };
      
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setEmailSent(true);
    } catch (error) {
      console.error('Email sign in error:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box sx={{
      minHeight: '100vh',
      backgroundColor: '#FFF7F1',
      py: 4
    }}>
      <Container maxWidth="lg">
        <Box textAlign="center" mb={4}>
          <Typography 
            variant="h3" 
            component="h1" 
            sx={{ 
              color: '#5C83BF',
              fontWeight: 600,
              mb: 2,
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }
            }}
          >
            Your Mental Wellness Journey Starts Here
          </Typography>
          <Typography 
            variant="h6" 
            sx={{ 
              color: 'text.secondary',
              fontWeight: 400,
              mb: 4
            }}
          >
            Join thousands discovering peace of mind, one AI-session at a time!
          </Typography>
        </Box>

        <Grid container spacing={4} alignItems="center">
          {!isMobile && (
            <Grid item xs={12} md={4}>
              <Fade in timeout={1000}>
                <ImageContainer>
                  <img 
                    src={leftImage} 
                    alt="Learning illustration" 
                    style={{
                      width: '100%',
                      maxWidth: 380,
                      height: 'auto',
                      position: 'relative',
                      zIndex: 1
                    }}
                  />
                </ImageContainer>
              </Fade>
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            <Fade in timeout={800}>
              <StyledPaper elevation={3}>
                <Grid container spacing={3} direction="column">
                  <Grid item>
                    <Typography 
                      variant="h4" 
                      component="h2"
                      textAlign="center"
                      fontWeight={500}
                      mb={1}
                    >
                      pokamind
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      textAlign="center"
                      sx={{ opacity: 0.9 }}
                    >
                      Sign up and start your journey <br /> Its free! 🚀 No credit card needed
                    </Typography>
                  </Grid>

                  {loading ? (
                    <Grid item container justifyContent="center">
                      <CircularProgress sx={{ color: 'white' }} />
                    </Grid>
                  ) : emailSent ? (
                    <Grid item>
                      <Alert 
                        severity="success" 
                        sx={{
                          backgroundColor: 'rgba(0, 255, 124, 0.15)',
                          color: 'white',
                          border: '1px solid rgba(0, 255, 124, 0.5)'
                        }}
                      >
                        <Typography variant="subtitle1" fontWeight="bold">Check your email</Typography>
                        <Typography variant="body2">Magic sign-in link sent! Click to continue.</Typography>
                      </Alert>
                    </Grid>
                  ) : (
                    <>
                      <Grid item>
                        <StyledTextField
                          fullWidth
                          label="Email"
                          variant="filled"
                          type="email"
                          required
                          error={!emailValid}
                          helperText={!emailValid && "Please enter a valid email"}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailValid(e.target.checkValidity());
                          }}
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>

                      <Grid item>
                        <AuthButton 
                          fullWidth 
                          variant="contained"
                          onClick={handleSignIn}
                        >
                          Sign up via email
                        </AuthButton>
                      </Grid>

                      <Grid item>
                        <Box display="flex" alignItems="center" gap={2}>
                          <Divider sx={{ flex: 1, borderColor: 'rgba(255,255,255,0.3)' }} />
                          <Typography color="white" variant="body2">OR SIGN UP WITH</Typography>
                          <Divider sx={{ flex: 1, borderColor: 'rgba(255,255,255,0.3)' }} />
                        </Box>
                      </Grid>

                      <Grid item>
                        <SocialButton
                          fullWidth
                          variant="outlined"
                          onClick={signInWithGoogle}
                          startIcon={<img src={googleIcon} alt="Google" style={{ width: 20, height: 20 }} />}
                        >
                          Google
                        </SocialButton>
                      </Grid>

                                            <Grid item>
                        <Box display="flex" alignItems="center" gap={2}>
                          <Divider sx={{ flex: 1, borderColor: 'rgba(255,255,255,0.3)' }} />
                          <Typography color="white" variant="body2">OR</Typography>
                          <Divider sx={{ flex: 1, borderColor: 'rgba(255,255,255,0.3)' }} />
                        </Box>
                      </Grid>

                      <Grid item>
                      <SlackButton
                        fullWidth
                        variant="outlined"
                        onClick={signInWithSlack}
                        startIcon={<SlackIcon sx={{ color: 'white' }} />}
                      >
                        Slack
                      </SlackButton>
                      </Grid>

                      <Grid item>
                        <Typography
                          variant="body2"
                          align="center"
                          sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.75rem' }}
                        >
                          By continuing, you agree to our{' '}
                          <Link
                            href={URL + "/terms"}
                            target="_blank"
                            rel="noreferrer"
                            sx={{ color: 'white' }}
                          >
                            Terms
                          </Link>
                          {' '}and{' '}
                          <Link
                            href={URL + "/privacy"}
                            target="_blank"
                            rel="noreferrer"
                            sx={{ color: 'white' }}
                          >
                            Privacy Policy
                          </Link>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </StyledPaper>
            </Fade>
          </Grid>

          {!isMobile && (
            <Grid item xs={12} md={4}>
              <Fade in timeout={1000}>
                <ImageContainer>
                  <img 
                    src={rightImage} 
                    alt="Growth illustration" 
                    style={{
                      width: '100%',
                      maxWidth: 380,
                      height: 'auto',
                      position: 'relative',
                      zIndex: 1,
                      marginTop: 30
                    }}
                  />
                </ImageContainer>
              </Fade>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default AuthSignup;