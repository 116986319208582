// src/utils/graphUtils.js
import Chart from 'chart.js/auto';

export const executeGraphCode = (graphCode, data, canvasElement) => {
  if (!canvasElement) {
    throw new Error('Canvas element is required');
  }

  try {
    const ctx = canvasElement.getContext('2d');
    
    if (!ctx) {
      throw new Error('Could not get canvas context');
    }

    // Extract all the data we might need
    const {
      session_times = [],
      session_count = 0,
      session_durations = [],
      energy_levels = [],
      departments = [],
      improved_data = [],
      session_topics = [], // Add this
      session_ids = [],   // Add this
      energy_count = 0,   // Add this
      users_list = []     // Add this
    } = data || {};

    // Pre-process topic data for pie charts
    const uniqueTopics = Array.from(new Set(session_topics.filter(topic => topic !== 'NoPreference')));
    const topicCounts = uniqueTopics.map(topic => 
      session_topics.filter(t => t === topic).length
    );

    // Remove the document.getElementById line from the code
    const cleanedCode = graphCode.replace(
      /const ctx.*getContext.*\n/,
      ''
    );

    // Create the function with all possible variables
    const chartFunction = new Function(
      'Chart',
      'ctx',
      'session_times',
      'session_count',
      'session_durations',
      'energy_levels',
      'departments',
      'improved_data',
      'session_topics',
      'session_ids',
      'energy_count',
      'users_list',
      'uniqueTopics',
      'topicCounts',
      `
      try {
        ${cleanedCode}
        return new Chart(ctx, config);
      } catch (error) {
        console.error('Error creating chart:', error);
        throw error;
      }
      `
    );

    // Execute the function with all variables
    return chartFunction(
      Chart,
      ctx,
      session_times,
      session_count,
      session_durations,
      energy_levels,
      departments,
      improved_data,
      session_topics,
      session_ids,
      energy_count,
      users_list,
      uniqueTopics,
      topicCounts
    );

  } catch (error) {
    console.error('Error in executeGraphCode:', error);
    throw error;
  }
};

export const validateGraphCode = (code) => {
  const requiredElements = [
    'const data =',
    'const config =',
    'type:',
    'data:',
    'options:'
  ];

  const dangerousPatterns = [
    'eval(',
    'Function(',
    'setTimeout',
    'setInterval',
    'document.write',
    '<script',
    'localStorage',
    'sessionStorage',
    'indexedDB',
    'window.location'
  ];

  const hasRequiredElements = requiredElements.every(element => 
    code.includes(element)
  );

  const hasDangerousPatterns = dangerousPatterns.some(pattern => 
    code.includes(pattern)
  );

  return hasRequiredElements && !hasDangerousPatterns;
};

export const destroyChart = (chartInstance) => {
  if (chartInstance && typeof chartInstance.destroy === 'function') {
    chartInstance.destroy();
  }
};

export const processChartData = (sessionData) => {
  if (!sessionData) return null;

  return {
    session_times: sessionData.session_times?.map(time => new Date(time).toLocaleDateString()) || [],
    session_count: sessionData.session_count || 0,
    session_durations: sessionData.session_durations || [],
    energy_levels: sessionData.energy_levels || [],
    departments: sessionData.departments || [],
    improved_data: sessionData.improved_data || [],
    session_topics: sessionData.session_topics || [],
    session_ids: sessionData.session_ids || [],
    energy_count: sessionData.energy_count || 0,
    users_list: sessionData.users_list || []
  };
};