import { Row } from 'react-bootstrap';
import './style.scss';
import HomePage from './home/HomePage';

const GetStarted = () => {

  return (
    <Row className="justify-content-center">
      <HomePage/>
    </Row>
  );
};

export default GetStarted;
