import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { URL } from '../Helpers';

const SlackAuthButton = () => {
  const handleSlackAuth = () => {
    // Store current URL as redirect location
    localStorage.setItem('slackAuthRedirect', window.location.pathname);
    
    // Redirect to backend OAuth start endpoint
    window.location.href = `${URL}/slack/oauth/start?source=slack_command`;
    console.log('Redirecting to Slack OAuth start endpoint');
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Button
        variant="contained"
        onClick={handleSlackAuth}
        sx={{
          backgroundColor: '#5C83BF', // Slack purple
          color: 'white',
          '&:hover': {
            backgroundColor: '#611f69'
          },
          // py: 1.5,
          // px: 3,
          borderRadius: 20,
          textTransform: 'none',
          fontSize: '1rem'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122 122">
            <path d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z" fill="#e01e5a"/>
            <path d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z" fill="#36c5f0"/>
            <path d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z" fill="#2eb67d"/>
            <path d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z" fill="#ecb22e"/>
          </svg>
          Add to Slack
        </Box>
      </Button>
      {/* Add Space */}
      <Box sx={{ height: 20 }} />
    </Box>
  );
};

export default SlackAuthButton;