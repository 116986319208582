// SlackAuthCallback.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import {
  Box,
  CircularProgress,
  Typography,
  Alert,
  Paper
} from '@mui/material';


const SlackAuthCallback = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { user: currentUser } = useUser();

  useEffect(() => {
    const handleAuth = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const firebaseToken = params.get('firebaseToken');
        const isNewUser = params.get('isNewUser') === 'true';
        const source = params.get('source') || 'webapp';

        console.log('Auth callback received with source:', source); // Debug log

        if (!firebaseToken) {
          throw new Error('No Firebase token provided');
        }

        const auth = getAuth();
        await signInWithCustomToken(auth, firebaseToken);

        // Navigate based on source and user state
        if (source === 'slack_command') {
          console.log('Navigating with slack command success message'); // Debug log
          navigate('/user-profile', {
            state: { 
              slackSuccess: true,
              message: 'Account linked successfully! You can now use /poka commands in Slack.' 
            },
            replace: true
          });
        } else if (isNewUser) {
          navigate('/get-started', {
            state: { 
              fromSlack: true,
              message: 'Welcome to Pokamind! Your account has been created successfully.' 
            },
            replace: true
          });
        } else {
          navigate('/user-profile', {
            state: { 
              slackSuccess: true,
              message: 'Success! Your Slack account has been linked.' 
            },
            replace: true
          });
        }

      } catch (err) {
        console.error('Slack auth error:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (!currentUser) {
      handleAuth();
    } else {
      // If user is already signed in, redirect to profile with success message
      const params = new URLSearchParams(location.search);
      const source = params.get('source');
      
      if (source === 'slack_command') {
        navigate('/user-profile', {
          state: { 
            slackSuccess: true,
            message: 'Account linked successfully! You can now use /poka commands in Slack.' 
          },
          replace: true
        });
      } else {
        navigate('/user-profile', { replace: true });
      }
    }
  }, [location, navigate, currentUser]);

  if (loading) {
    return (
      <Box 
        sx={{ 
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#FFF7F1'
        }}
      >
        <Paper 
          elevation={3}
          sx={{ 
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#5C83BF',
            color: 'white',
            borderRadius: 2,
            maxWidth: '90%',
            width: '400px'
          }}
        >
          <CircularProgress sx={{ color: 'white', mb: 2 }} />
          <Typography variant="h6" align="center">
            Completing Slack authentication...
          </Typography>
        </Paper>
      </Box>
    );
  }

  if (error) {
    return (
      <Box 
        sx={{ 
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#FFF7F1'
        }}
      >
        <Paper 
          elevation={3}
          sx={{ 
            p: 4,
            maxWidth: '90%',
            width: '400px',
            backgroundColor: '#5C83BF',
            color: 'white',
            borderRadius: 2
          }}
        >
          <Typography variant="h6" gutterBottom align="center">
            Authentication Error
          </Typography>
          <Alert 
            severity="error" 
            sx={{ 
              mb: 3,
              '& .MuiAlert-message': {
                width: '100%'
              }
            }}
          >
            {error}
          </Alert>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Typography variant="body2" align="center">
              Please try again or contact support if the problem persists.
            </Typography>
          </Box>
        </Paper>
      </Box>
    );
  }

  return null;
};

export default SlackAuthCallback;